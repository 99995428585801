import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _622cde39 = () => interopDefault(import('..\\pages\\academy\\index.vue' /* webpackChunkName: "pages_academy_index" */))
const _537c1aec = () => interopDefault(import('..\\pages\\academy-signup.vue' /* webpackChunkName: "pages_academy-signup" */))
const _4552d05b = () => interopDefault(import('..\\pages\\accept.vue' /* webpackChunkName: "pages_accept" */))
const _45328618 = () => interopDefault(import('..\\pages\\accept-contract.vue' /* webpackChunkName: "pages_accept-contract" */))
const _c73f5e72 = () => interopDefault(import('..\\pages\\activate-secondary-mail.vue' /* webpackChunkName: "pages_activate-secondary-mail" */))
const _7130464c = () => interopDefault(import('..\\pages\\admin.vue' /* webpackChunkName: "pages_admin" */))
const _2ac6c2c6 = () => interopDefault(import('..\\pages\\biva\\index.vue' /* webpackChunkName: "pages_biva_index" */))
const _35a62a77 = () => interopDefault(import('..\\pages\\bluetooth\\index.vue' /* webpackChunkName: "pages_bluetooth_index" */))
const _55de4326 = () => interopDefault(import('..\\pages\\completeProfile.vue' /* webpackChunkName: "pages_completeProfile" */))
const _7ff61cd6 = () => interopDefault(import('..\\pages\\config.vue' /* webpackChunkName: "pages_config" */))
const _0158c2a4 = () => interopDefault(import('..\\pages\\customer-signup.vue' /* webpackChunkName: "pages_customer-signup" */))
const _3fc0f24f = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))
const _73613c86 = () => interopDefault(import('..\\pages\\fitness\\index.vue' /* webpackChunkName: "pages_fitness_index" */))
const _7c4078f6 = () => interopDefault(import('..\\pages\\forgot.vue' /* webpackChunkName: "pages_forgot" */))
const _9d9f607c = () => interopDefault(import('..\\pages\\forgot-password.vue' /* webpackChunkName: "pages_forgot-password" */))
const _7388c90a = () => interopDefault(import('..\\pages\\groups\\index.vue' /* webpackChunkName: "pages_groups_index" */))
const _78f1f5ba = () => interopDefault(import('..\\pages\\HBO-signup.vue' /* webpackChunkName: "pages_HBO-signup" */))
const _1a8d329a = () => interopDefault(import('..\\pages\\keto\\index.vue' /* webpackChunkName: "pages_keto_index" */))
const _09689566 = () => interopDefault(import('..\\pages\\login.vue' /* webpackChunkName: "pages_login" */))
const _5453fd10 = () => interopDefault(import('..\\pages\\regional\\index.vue' /* webpackChunkName: "pages_regional_index" */))
const _2ef93f68 = () => interopDefault(import('..\\pages\\reset.vue' /* webpackChunkName: "pages_reset" */))
const _4ae20baa = () => interopDefault(import('..\\pages\\schedule.vue' /* webpackChunkName: "pages_schedule" */))
const _641c30fb = () => interopDefault(import('..\\pages\\search.vue' /* webpackChunkName: "pages_search" */))
const _c4a746e8 = () => interopDefault(import('..\\pages\\settings\\index.vue' /* webpackChunkName: "pages_settings_index" */))
const _7004046b = () => interopDefault(import('..\\pages\\signup.vue' /* webpackChunkName: "pages_signup" */))
const _5b1bb240 = () => interopDefault(import('..\\pages\\update-required.vue' /* webpackChunkName: "pages_update-required" */))
const _bcb34f46 = () => interopDefault(import('..\\pages\\users\\index.vue' /* webpackChunkName: "pages_users_index" */))
const _53c78f1c = () => interopDefault(import('..\\pages\\academy\\material\\index.vue' /* webpackChunkName: "pages_academy_material_index" */))
const _4052bce9 = () => interopDefault(import('..\\pages\\fitness\\groups\\index.vue' /* webpackChunkName: "pages_fitness_groups_index" */))
const _5c04b1fc = () => interopDefault(import('..\\pages\\groups\\deleted.vue' /* webpackChunkName: "pages_groups_deleted" */))
const _be573e74 = () => interopDefault(import('..\\pages\\keto\\groups\\index.vue' /* webpackChunkName: "pages_keto_groups_index" */))
const _0bbc553c = () => interopDefault(import('..\\pages\\regional\\groups\\index.vue' /* webpackChunkName: "pages_regional_groups_index" */))
const _590d4272 = () => interopDefault(import('..\\pages\\settings\\documents.vue' /* webpackChunkName: "pages_settings_documents" */))
const _25738ef0 = () => interopDefault(import('..\\pages\\settings\\licenses.vue' /* webpackChunkName: "pages_settings_licenses" */))
const _22d224f0 = () => interopDefault(import('..\\pages\\fitness\\groups\\deleted.vue' /* webpackChunkName: "pages_fitness_groups_deleted" */))
const _697a458d = () => interopDefault(import('..\\pages\\keto\\groups\\deleted.vue' /* webpackChunkName: "pages_keto_groups_deleted" */))
const _8b9216fa = () => interopDefault(import('..\\pages\\regional\\groups\\deleted.vue' /* webpackChunkName: "pages_regional_groups_deleted" */))
const _5df90514 = () => interopDefault(import('..\\pages\\biva\\regional\\_sessionId.vue' /* webpackChunkName: "pages_biva_regional__sessionId" */))
const _29304bf4 = () => interopDefault(import('..\\pages\\biva\\sessions\\_sessionId.vue' /* webpackChunkName: "pages_biva_sessions__sessionId" */))
const _34f54de4 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\index.vue' /* webpackChunkName: "pages_fitness_groups__groupId_index" */))
const _fff4e6aa = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\index.vue' /* webpackChunkName: "pages_keto_groups__groupId_index" */))
const _51f6e5a1 = () => interopDefault(import('..\\pages\\regional\\groups\\_groupId\\index.vue' /* webpackChunkName: "pages_regional_groups__groupId_index" */))
const _45ce4bd5 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\deleted.vue' /* webpackChunkName: "pages_fitness_groups__groupId_deleted" */))
const _41100c32 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\deleted.vue' /* webpackChunkName: "pages_keto_groups__groupId_deleted" */))
const _5c0effa8 = () => interopDefault(import('..\\pages\\regional\\groups\\_groupId\\deleted.vue' /* webpackChunkName: "pages_regional_groups__groupId_deleted" */))
const _11391d1f = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\index.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_index" */))
const _2dac0cfc = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\index.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_index" */))
const _4596ad1c = () => interopDefault(import('..\\pages\\regional\\groups\\_groupId\\patients\\_patientId\\index.vue' /* webpackChunkName: "pages_regional_groups__groupId_patients__patientId_index" */))
const _1f2c6fa8 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\somatotype.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_somatotype" */))
const _4955e1eb = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\somatotype.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_somatotype" */))
const _5e125be8 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\deleted.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams_deleted" */))
const _d43a16aa = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\deleted.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams_deleted" */))
const _3a23fcf5 = () => interopDefault(import('..\\pages\\regional\\groups\\_groupId\\patients\\_patientId\\exams\\deleted.vue' /* webpackChunkName: "pages_regional_groups__groupId_patients__patientId_exams_deleted" */))
const _79c349b6 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\index.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_bia__biaId_index" */))
const _2bfe05fb = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\cardio\\_cardioId\\index.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_cardio__cardioId_index" */))
const _a172050a = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\circumference\\_circumferenceId\\index.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_circumference__circumferenceId_index" */))
const _4c2e5cfa = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\index.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_index" */))
const _5e7f5ac9 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\fitness\\_fitnessId\\index.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_fitness__fitnessId_index" */))
const _33f95afc = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\skinfolds\\_skinfoldId\\index.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_skinfolds__skinfoldId_index" */))
const _dd5cd778 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\trainings\\_trainingId\\index.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_trainings__trainingId_index" */))
const _08c24b30 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\index.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_bia__biaId_index" */))
const _1424c6fe = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\cardio\\_cardioId\\index.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_cardio__cardioId_index" */))
const _54cb8584 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\circumference\\_circumferenceId\\index.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_circumference__circumferenceId_index" */))
const _7d87f2d7 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\index.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_index" */))
const _500c31e8 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\fitness\\_fitnessId\\index.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_fitness__fitnessId_index" */))
const _5ffc784e = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\skinfolds\\_skinfoldId\\index.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_skinfolds__skinfoldId_index" */))
const _2d59fd21 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\trainings\\_trainingId\\index.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_trainings__trainingId_index" */))
const _62b63966 = () => interopDefault(import('..\\pages\\regional\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\index.vue' /* webpackChunkName: "pages_regional_groups__groupId_patients__patientId_exams__examId_index" */))
const _822963e6 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\bia.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_bia__biaId_bia" */))
const _deffb8a2 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\fitnessBia.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_bia__biaId_fitnessBia" */))
const _73422a9e = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\fluidRetention.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_bia__biaId_fluidRetention" */))
const _7afddede = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\qualitative.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_bia__biaId_qualitative" */))
const _90537658 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\quantitative.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_bia__biaId_quantitative" */))
const _15163d20 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\reports\\index.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_bia__biaId_reports_index" */))
const _bf974e9e = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\cardio\\_cardioId\\hr.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_cardio__cardioId_hr" */))
const _cfd0a4a2 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\circumference\\_circumferenceId\\radar.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_circumference__circumferenceId_radar" */))
const _c3687dbc = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\bia.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_bia" */))
const _3a0bdebf = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\circumferenceRadar.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_circumferenceRadar" */))
const _51c0fefa = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\fitnessBia.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_fitnessBia" */))
const _0c49af08 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\fluidRetention.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_fluidRetention" */))
const _46ea7aea = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\functional.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_functional" */))
const _649f0bdc = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\hr.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_hr" */))
const _7a35c7ee = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\load.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_load" */))
const _3c4f261a = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\qualitative.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_qualitative" */))
const _2c4d795f = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\quantitative.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_quantitative" */))
const _28f11f3b = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\index.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_reports_index" */))
const _a9e3a060 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\skinfoldRadar.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_skinfoldRadar" */))
const _6c5be0ad = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\somatotype.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_somatotype" */))
const _0ccb4f40 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\zscore.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_zscore" */))
const _50e9e3c8 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\fitness\\_fitnessId\\functional.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_fitness__fitnessId_functional" */))
const _1cca0b30 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\skinfolds\\_skinfoldId\\radar.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_skinfolds__skinfoldId_radar" */))
const _40ed0138 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\trainings\\_trainingId\\load.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_trainings__trainingId_load" */))
const _44f8c210 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\bia.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_bia__biaId_bia" */))
const _5c689368 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\fitnessBia.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_bia__biaId_fitnessBia" */))
const _7c35e6ce = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\fluidRetention.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_bia__biaId_fluidRetention" */))
const _63249fe1 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\qualitative.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_bia__biaId_qualitative" */))
const _56f0b79e = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\quantitative.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_bia__biaId_quantitative" */))
const _a96844e6 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\reports\\index.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_bia__biaId_reports_index" */))
const _5ce422e4 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\cardio\\_cardioId\\hr.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_cardio__cardioId_hr" */))
const _832a251c = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\circumference\\_circumferenceId\\radar.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_circumference__circumferenceId_radar" */))
const _75db2a3f = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\bia.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_bia" */))
const _57b90ffc = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\circumferenceRadar.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_circumferenceRadar" */))
const _1a9ee750 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\estimates.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_estimates" */))
const _6988e986 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\fitnessBia.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_fitnessBia" */))
const _42404982 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\fluidRetention.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_fluidRetention" */))
const _56054ece = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\functional.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_functional" */))
const _0630f955 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\hr.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_hr" */))
const _66886397 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\ketone.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_ketone" */))
const _d6d8fb1e = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\load.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_load" */))
const _d0a12de0 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\qualitative.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_qualitative" */))
const _315600e2 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\quantitative.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_quantitative" */))
const _0df5d1fe = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\index.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_reports_index" */))
const _34532620 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\somatotype.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_somatotype" */))
const _b33f6af4 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\weightLoss.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_weightLoss" */))
const _191903ba = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\zscore.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_zscore" */))
const _d9c6988e = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\fitness\\_fitnessId\\functional.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_fitness__fitnessId_functional" */))
const _8e5b17e6 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\skinfolds\\_skinfoldId\\radar.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_skinfolds__skinfoldId_radar" */))
const _36dbf232 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\trainings\\_trainingId\\load.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_trainings__trainingId_load" */))
const _7745c035 = () => interopDefault(import('..\\pages\\regional\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\bia.vue' /* webpackChunkName: "pages_regional_groups__groupId_patients__patientId_exams__examId_bia" */))
const _9f7df370 = () => interopDefault(import('..\\pages\\regional\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\index.vue' /* webpackChunkName: "pages_regional_groups__groupId_patients__patientId_exams__examId_reports_index" */))
const _45aed450 = () => interopDefault(import('..\\pages\\regional\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\vector.vue' /* webpackChunkName: "pages_regional_groups__groupId_patients__patientId_exams__examId_vector" */))
const _d5cacc78 = () => interopDefault(import('..\\pages\\regional\\groups\\_groupId\\patients\\_patientId\\histories\\_historyId\\attachments\\index.vue' /* webpackChunkName: "pages_regional_groups__groupId_patients__patientId_histories__historyId_attachments_index" */))
const _2aebc346 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\reports\\_reportSlug\\index.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_bia__biaId_reports__reportSlug_index" */))
const _74d9875b = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\_reportSlug\\index.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_reports__reportSlug_index" */))
const _096e1e49 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\reports\\_reportSlug\\index.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_bia__biaId_reports__reportSlug_index" */))
const _1876a978 = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\_reportSlug\\index.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_reports__reportSlug_index" */))
const _1f1c3d6e = () => interopDefault(import('..\\pages\\regional\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\_reportSlug\\index.vue' /* webpackChunkName: "pages_regional_groups__groupId_patients__patientId_exams__examId_reports__reportSlug_index" */))
const _5b41bde0 = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\reports\\_reportSlug\\iframe.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_bia__biaId_reports__reportSlug_iframe" */))
const _4f0c7c6b = () => interopDefault(import('..\\pages\\fitness\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\_reportSlug\\iframe.vue' /* webpackChunkName: "pages_fitness_groups__groupId_patients__patientId_exams__examId_reports__reportSlug_iframe" */))
const _4d0ac33d = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\bia\\_biaId\\reports\\_reportSlug\\iframe.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_bia__biaId_reports__reportSlug_iframe" */))
const _1f139dee = () => interopDefault(import('..\\pages\\keto\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\_reportSlug\\iframe.vue' /* webpackChunkName: "pages_keto_groups__groupId_patients__patientId_exams__examId_reports__reportSlug_iframe" */))
const _25beee90 = () => interopDefault(import('..\\pages\\regional\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\_reportSlug\\iframe.vue' /* webpackChunkName: "pages_regional_groups__groupId_patients__patientId_exams__examId_reports__reportSlug_iframe" */))
const _66dea920 = () => interopDefault(import('..\\pages\\groups\\_groupId\\index.vue' /* webpackChunkName: "pages_groups__groupId_index" */))
const _54ccdec8 = () => interopDefault(import('..\\pages\\groups\\_groupId\\bia.vue' /* webpackChunkName: "pages_groups__groupId_bia" */))
const _53d84132 = () => interopDefault(import('..\\pages\\groups\\_groupId\\deleted.vue' /* webpackChunkName: "pages_groups__groupId_deleted" */))
const _5d99989e = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\index.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_index" */))
const _53b538b8 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\activities\\index.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_activities_index" */))
const _0cabb2f2 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\bodyscan\\index.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_bodyscan_index" */))
const _3d395692 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\grid.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_grid" */))
const _09e8b708 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\schedule.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_schedule" */))
const _5f2d6956 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\bodyscan\\grid.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_bodyscan_grid" */))
const _ce18ecd4 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\exams\\deleted.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_exams_deleted" */))
const _6a61b8aa = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\bodyscan\\_bodyscanExamId\\index.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_bodyscan__bodyscanExamId_index" */))
const _721d0f8c = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\index.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_exams__examId_index" */))
const _50f12af6 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\overview\\_formulaId\\index.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_overview__formulaId_index" */))
const _61d1e98b = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\bodyscan\\_bodyscanExamId\\reports\\index.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_bodyscan__bodyscanExamId_reports_index" */))
const _09ec1f98 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\bia.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_exams__examId_bia" */))
const _513ca185 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\estimates.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_exams__examId_estimates" */))
const _6970719c = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\hydra.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_exams__examId_hydra" */))
const _38e67661 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\interpretive.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_exams__examId_interpretive" */))
const _5dc0ae8b = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\measures.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_exams__examId_measures" */))
const _91d50be0 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\percentiles.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_exams__examId_percentiles" */))
const _314d30e9 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\index.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_exams__examId_reports_index" */))
const _2080b143 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\edit.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_exams__examId_reports_edit" */))
const _2db266e8 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\default\\_reportSlug\\index.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_exams__examId_reports_default__reportSlug_index" */))
const _6467dd5a = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\default\\_reportSlug\\iframe.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_exams__examId_reports_default__reportSlug_iframe" */))
const _01cd1730 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\default\\_reportSlug\\new\\index.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_exams__examId_reports_default__reportSlug_new_index" */))
const _3e5fff0b = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\bodyscan\\_bodyscanExamId\\reports\\_reportSlug\\index.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_bodyscan__bodyscanExamId_reports__reportSlug_index" */))
const _35ddf906 = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\_reportId\\index.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_exams__examId_reports__reportId_index" */))
const _93560a8a = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\bodyscan\\_bodyscanExamId\\reports\\_reportSlug\\iframe.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_bodyscan__bodyscanExamId_reports__reportSlug_iframe" */))
const _3476f6ee = () => interopDefault(import('..\\pages\\groups\\_groupId\\patients\\_patientId\\exams\\_examId\\reports\\_reportId\\iframe.vue' /* webpackChunkName: "pages_groups__groupId_patients__patientId_exams__examId_reports__reportId_iframe" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/academy",
    component: _622cde39,
    name: "academy___en"
  }, {
    path: "/academy-signup",
    component: _537c1aec,
    name: "academy-signup___en"
  }, {
    path: "/accept",
    component: _4552d05b,
    name: "accept___en"
  }, {
    path: "/accept-contract",
    component: _45328618,
    name: "accept-contract___en"
  }, {
    path: "/activate-secondary-mail",
    component: _c73f5e72,
    name: "activate-secondary-mail___en"
  }, {
    path: "/admin",
    component: _7130464c,
    name: "admin___en"
  }, {
    path: "/biva",
    component: _2ac6c2c6,
    name: "biva___en"
  }, {
    path: "/bluetooth",
    component: _35a62a77,
    name: "bluetooth___en"
  }, {
    path: "/completeProfile",
    component: _55de4326,
    name: "completeProfile___en"
  }, {
    path: "/config",
    component: _7ff61cd6,
    name: "config___en"
  }, {
    path: "/customer-signup",
    component: _0158c2a4,
    name: "customer-signup___en"
  }, {
    path: "/de",
    component: _3fc0f24f,
    name: "index___de"
  }, {
    path: "/el",
    component: _3fc0f24f,
    name: "index___el"
  }, {
    path: "/en-gb",
    component: _3fc0f24f,
    name: "index___en-gb"
  }, {
    path: "/es",
    component: _3fc0f24f,
    name: "index___es"
  }, {
    path: "/fitness",
    component: _73613c86,
    name: "fitness___en"
  }, {
    path: "/forgot",
    component: _7c4078f6,
    name: "forgot___en"
  }, {
    path: "/forgot-password",
    component: _9d9f607c,
    name: "forgot-password___en"
  }, {
    path: "/fr",
    component: _3fc0f24f,
    name: "index___fr"
  }, {
    path: "/groups",
    component: _7388c90a,
    name: "groups___en"
  }, {
    path: "/HBO-signup",
    component: _78f1f5ba,
    name: "HBO-signup___en"
  }, {
    path: "/it",
    component: _3fc0f24f,
    name: "index___it"
  }, {
    path: "/keto",
    component: _1a8d329a,
    name: "keto___en"
  }, {
    path: "/login",
    component: _09689566,
    name: "login___en"
  }, {
    path: "/nl",
    component: _3fc0f24f,
    name: "index___nl"
  }, {
    path: "/nl-be",
    component: _3fc0f24f,
    name: "index___nl-be"
  }, {
    path: "/pl",
    component: _3fc0f24f,
    name: "index___pl"
  }, {
    path: "/regional",
    component: _5453fd10,
    name: "regional___en"
  }, {
    path: "/reset",
    component: _2ef93f68,
    name: "reset___en"
  }, {
    path: "/schedule",
    component: _4ae20baa,
    name: "schedule___en"
  }, {
    path: "/search",
    component: _641c30fb,
    name: "search___en"
  }, {
    path: "/settings",
    component: _c4a746e8,
    name: "settings___en"
  }, {
    path: "/signup",
    component: _7004046b,
    name: "signup___en"
  }, {
    path: "/update-required",
    component: _5b1bb240,
    name: "update-required___en"
  }, {
    path: "/users",
    component: _bcb34f46,
    name: "users___en"
  }, {
    path: "/zh",
    component: _3fc0f24f,
    name: "index___zh"
  }, {
    path: "/academy/material",
    component: _53c78f1c,
    name: "academy-material___en"
  }, {
    path: "/de/academy",
    component: _622cde39,
    name: "academy___de"
  }, {
    path: "/de/academy-signup",
    component: _537c1aec,
    name: "academy-signup___de"
  }, {
    path: "/de/accept",
    component: _4552d05b,
    name: "accept___de"
  }, {
    path: "/de/accept-contract",
    component: _45328618,
    name: "accept-contract___de"
  }, {
    path: "/de/activate-secondary-mail",
    component: _c73f5e72,
    name: "activate-secondary-mail___de"
  }, {
    path: "/de/admin",
    component: _7130464c,
    name: "admin___de"
  }, {
    path: "/de/biva",
    component: _2ac6c2c6,
    name: "biva___de"
  }, {
    path: "/de/bluetooth",
    component: _35a62a77,
    name: "bluetooth___de"
  }, {
    path: "/de/completeProfile",
    component: _55de4326,
    name: "completeProfile___de"
  }, {
    path: "/de/config",
    component: _7ff61cd6,
    name: "config___de"
  }, {
    path: "/de/customer-signup",
    component: _0158c2a4,
    name: "customer-signup___de"
  }, {
    path: "/de/fitness",
    component: _73613c86,
    name: "fitness___de"
  }, {
    path: "/de/forgot",
    component: _7c4078f6,
    name: "forgot___de"
  }, {
    path: "/de/forgot-password",
    component: _9d9f607c,
    name: "forgot-password___de"
  }, {
    path: "/de/groups",
    component: _7388c90a,
    name: "groups___de"
  }, {
    path: "/de/HBO-signup",
    component: _78f1f5ba,
    name: "HBO-signup___de"
  }, {
    path: "/de/keto",
    component: _1a8d329a,
    name: "keto___de"
  }, {
    path: "/de/login",
    component: _09689566,
    name: "login___de"
  }, {
    path: "/de/regional",
    component: _5453fd10,
    name: "regional___de"
  }, {
    path: "/de/reset",
    component: _2ef93f68,
    name: "reset___de"
  }, {
    path: "/de/schedule",
    component: _4ae20baa,
    name: "schedule___de"
  }, {
    path: "/de/search",
    component: _641c30fb,
    name: "search___de"
  }, {
    path: "/de/settings",
    component: _c4a746e8,
    name: "settings___de"
  }, {
    path: "/de/signup",
    component: _7004046b,
    name: "signup___de"
  }, {
    path: "/de/update-required",
    component: _5b1bb240,
    name: "update-required___de"
  }, {
    path: "/de/users",
    component: _bcb34f46,
    name: "users___de"
  }, {
    path: "/el/academy",
    component: _622cde39,
    name: "academy___el"
  }, {
    path: "/el/academy-signup",
    component: _537c1aec,
    name: "academy-signup___el"
  }, {
    path: "/el/accept",
    component: _4552d05b,
    name: "accept___el"
  }, {
    path: "/el/accept-contract",
    component: _45328618,
    name: "accept-contract___el"
  }, {
    path: "/el/activate-secondary-mail",
    component: _c73f5e72,
    name: "activate-secondary-mail___el"
  }, {
    path: "/el/admin",
    component: _7130464c,
    name: "admin___el"
  }, {
    path: "/el/biva",
    component: _2ac6c2c6,
    name: "biva___el"
  }, {
    path: "/el/bluetooth",
    component: _35a62a77,
    name: "bluetooth___el"
  }, {
    path: "/el/completeProfile",
    component: _55de4326,
    name: "completeProfile___el"
  }, {
    path: "/el/config",
    component: _7ff61cd6,
    name: "config___el"
  }, {
    path: "/el/customer-signup",
    component: _0158c2a4,
    name: "customer-signup___el"
  }, {
    path: "/el/fitness",
    component: _73613c86,
    name: "fitness___el"
  }, {
    path: "/el/forgot",
    component: _7c4078f6,
    name: "forgot___el"
  }, {
    path: "/el/forgot-password",
    component: _9d9f607c,
    name: "forgot-password___el"
  }, {
    path: "/el/groups",
    component: _7388c90a,
    name: "groups___el"
  }, {
    path: "/el/HBO-signup",
    component: _78f1f5ba,
    name: "HBO-signup___el"
  }, {
    path: "/el/keto",
    component: _1a8d329a,
    name: "keto___el"
  }, {
    path: "/el/login",
    component: _09689566,
    name: "login___el"
  }, {
    path: "/el/regional",
    component: _5453fd10,
    name: "regional___el"
  }, {
    path: "/el/reset",
    component: _2ef93f68,
    name: "reset___el"
  }, {
    path: "/el/schedule",
    component: _4ae20baa,
    name: "schedule___el"
  }, {
    path: "/el/search",
    component: _641c30fb,
    name: "search___el"
  }, {
    path: "/el/settings",
    component: _c4a746e8,
    name: "settings___el"
  }, {
    path: "/el/signup",
    component: _7004046b,
    name: "signup___el"
  }, {
    path: "/el/update-required",
    component: _5b1bb240,
    name: "update-required___el"
  }, {
    path: "/el/users",
    component: _bcb34f46,
    name: "users___el"
  }, {
    path: "/en-gb/academy",
    component: _622cde39,
    name: "academy___en-gb"
  }, {
    path: "/en-gb/academy-signup",
    component: _537c1aec,
    name: "academy-signup___en-gb"
  }, {
    path: "/en-gb/accept",
    component: _4552d05b,
    name: "accept___en-gb"
  }, {
    path: "/en-gb/accept-contract",
    component: _45328618,
    name: "accept-contract___en-gb"
  }, {
    path: "/en-gb/activate-secondary-mail",
    component: _c73f5e72,
    name: "activate-secondary-mail___en-gb"
  }, {
    path: "/en-gb/admin",
    component: _7130464c,
    name: "admin___en-gb"
  }, {
    path: "/en-gb/biva",
    component: _2ac6c2c6,
    name: "biva___en-gb"
  }, {
    path: "/en-gb/bluetooth",
    component: _35a62a77,
    name: "bluetooth___en-gb"
  }, {
    path: "/en-gb/completeProfile",
    component: _55de4326,
    name: "completeProfile___en-gb"
  }, {
    path: "/en-gb/config",
    component: _7ff61cd6,
    name: "config___en-gb"
  }, {
    path: "/en-gb/customer-signup",
    component: _0158c2a4,
    name: "customer-signup___en-gb"
  }, {
    path: "/en-gb/fitness",
    component: _73613c86,
    name: "fitness___en-gb"
  }, {
    path: "/en-gb/forgot",
    component: _7c4078f6,
    name: "forgot___en-gb"
  }, {
    path: "/en-gb/forgot-password",
    component: _9d9f607c,
    name: "forgot-password___en-gb"
  }, {
    path: "/en-gb/groups",
    component: _7388c90a,
    name: "groups___en-gb"
  }, {
    path: "/en-gb/HBO-signup",
    component: _78f1f5ba,
    name: "HBO-signup___en-gb"
  }, {
    path: "/en-gb/keto",
    component: _1a8d329a,
    name: "keto___en-gb"
  }, {
    path: "/en-gb/login",
    component: _09689566,
    name: "login___en-gb"
  }, {
    path: "/en-gb/regional",
    component: _5453fd10,
    name: "regional___en-gb"
  }, {
    path: "/en-gb/reset",
    component: _2ef93f68,
    name: "reset___en-gb"
  }, {
    path: "/en-gb/schedule",
    component: _4ae20baa,
    name: "schedule___en-gb"
  }, {
    path: "/en-gb/search",
    component: _641c30fb,
    name: "search___en-gb"
  }, {
    path: "/en-gb/settings",
    component: _c4a746e8,
    name: "settings___en-gb"
  }, {
    path: "/en-gb/signup",
    component: _7004046b,
    name: "signup___en-gb"
  }, {
    path: "/en-gb/update-required",
    component: _5b1bb240,
    name: "update-required___en-gb"
  }, {
    path: "/en-gb/users",
    component: _bcb34f46,
    name: "users___en-gb"
  }, {
    path: "/es/academy",
    component: _622cde39,
    name: "academy___es"
  }, {
    path: "/es/academy-signup",
    component: _537c1aec,
    name: "academy-signup___es"
  }, {
    path: "/es/accept",
    component: _4552d05b,
    name: "accept___es"
  }, {
    path: "/es/accept-contract",
    component: _45328618,
    name: "accept-contract___es"
  }, {
    path: "/es/activate-secondary-mail",
    component: _c73f5e72,
    name: "activate-secondary-mail___es"
  }, {
    path: "/es/admin",
    component: _7130464c,
    name: "admin___es"
  }, {
    path: "/es/biva",
    component: _2ac6c2c6,
    name: "biva___es"
  }, {
    path: "/es/bluetooth",
    component: _35a62a77,
    name: "bluetooth___es"
  }, {
    path: "/es/completeProfile",
    component: _55de4326,
    name: "completeProfile___es"
  }, {
    path: "/es/config",
    component: _7ff61cd6,
    name: "config___es"
  }, {
    path: "/es/customer-signup",
    component: _0158c2a4,
    name: "customer-signup___es"
  }, {
    path: "/es/fitness",
    component: _73613c86,
    name: "fitness___es"
  }, {
    path: "/es/forgot",
    component: _7c4078f6,
    name: "forgot___es"
  }, {
    path: "/es/forgot-password",
    component: _9d9f607c,
    name: "forgot-password___es"
  }, {
    path: "/es/groups",
    component: _7388c90a,
    name: "groups___es"
  }, {
    path: "/es/HBO-signup",
    component: _78f1f5ba,
    name: "HBO-signup___es"
  }, {
    path: "/es/keto",
    component: _1a8d329a,
    name: "keto___es"
  }, {
    path: "/es/login",
    component: _09689566,
    name: "login___es"
  }, {
    path: "/es/regional",
    component: _5453fd10,
    name: "regional___es"
  }, {
    path: "/es/reset",
    component: _2ef93f68,
    name: "reset___es"
  }, {
    path: "/es/schedule",
    component: _4ae20baa,
    name: "schedule___es"
  }, {
    path: "/es/search",
    component: _641c30fb,
    name: "search___es"
  }, {
    path: "/es/settings",
    component: _c4a746e8,
    name: "settings___es"
  }, {
    path: "/es/signup",
    component: _7004046b,
    name: "signup___es"
  }, {
    path: "/es/update-required",
    component: _5b1bb240,
    name: "update-required___es"
  }, {
    path: "/es/users",
    component: _bcb34f46,
    name: "users___es"
  }, {
    path: "/fitness/groups",
    component: _4052bce9,
    name: "fitness-groups___en"
  }, {
    path: "/fr/academy",
    component: _622cde39,
    name: "academy___fr"
  }, {
    path: "/fr/academy-signup",
    component: _537c1aec,
    name: "academy-signup___fr"
  }, {
    path: "/fr/accept",
    component: _4552d05b,
    name: "accept___fr"
  }, {
    path: "/fr/accept-contract",
    component: _45328618,
    name: "accept-contract___fr"
  }, {
    path: "/fr/activate-secondary-mail",
    component: _c73f5e72,
    name: "activate-secondary-mail___fr"
  }, {
    path: "/fr/admin",
    component: _7130464c,
    name: "admin___fr"
  }, {
    path: "/fr/biva",
    component: _2ac6c2c6,
    name: "biva___fr"
  }, {
    path: "/fr/bluetooth",
    component: _35a62a77,
    name: "bluetooth___fr"
  }, {
    path: "/fr/completeProfile",
    component: _55de4326,
    name: "completeProfile___fr"
  }, {
    path: "/fr/config",
    component: _7ff61cd6,
    name: "config___fr"
  }, {
    path: "/fr/customer-signup",
    component: _0158c2a4,
    name: "customer-signup___fr"
  }, {
    path: "/fr/fitness",
    component: _73613c86,
    name: "fitness___fr"
  }, {
    path: "/fr/forgot",
    component: _7c4078f6,
    name: "forgot___fr"
  }, {
    path: "/fr/forgot-password",
    component: _9d9f607c,
    name: "forgot-password___fr"
  }, {
    path: "/fr/groups",
    component: _7388c90a,
    name: "groups___fr"
  }, {
    path: "/fr/HBO-signup",
    component: _78f1f5ba,
    name: "HBO-signup___fr"
  }, {
    path: "/fr/keto",
    component: _1a8d329a,
    name: "keto___fr"
  }, {
    path: "/fr/login",
    component: _09689566,
    name: "login___fr"
  }, {
    path: "/fr/regional",
    component: _5453fd10,
    name: "regional___fr"
  }, {
    path: "/fr/reset",
    component: _2ef93f68,
    name: "reset___fr"
  }, {
    path: "/fr/schedule",
    component: _4ae20baa,
    name: "schedule___fr"
  }, {
    path: "/fr/search",
    component: _641c30fb,
    name: "search___fr"
  }, {
    path: "/fr/settings",
    component: _c4a746e8,
    name: "settings___fr"
  }, {
    path: "/fr/signup",
    component: _7004046b,
    name: "signup___fr"
  }, {
    path: "/fr/update-required",
    component: _5b1bb240,
    name: "update-required___fr"
  }, {
    path: "/fr/users",
    component: _bcb34f46,
    name: "users___fr"
  }, {
    path: "/groups/deleted",
    component: _5c04b1fc,
    name: "groups-deleted___en"
  }, {
    path: "/it/academy",
    component: _622cde39,
    name: "academy___it"
  }, {
    path: "/it/academy-signup",
    component: _537c1aec,
    name: "academy-signup___it"
  }, {
    path: "/it/accept",
    component: _4552d05b,
    name: "accept___it"
  }, {
    path: "/it/accept-contract",
    component: _45328618,
    name: "accept-contract___it"
  }, {
    path: "/it/activate-secondary-mail",
    component: _c73f5e72,
    name: "activate-secondary-mail___it"
  }, {
    path: "/it/admin",
    component: _7130464c,
    name: "admin___it"
  }, {
    path: "/it/biva",
    component: _2ac6c2c6,
    name: "biva___it"
  }, {
    path: "/it/bluetooth",
    component: _35a62a77,
    name: "bluetooth___it"
  }, {
    path: "/it/completeProfile",
    component: _55de4326,
    name: "completeProfile___it"
  }, {
    path: "/it/config",
    component: _7ff61cd6,
    name: "config___it"
  }, {
    path: "/it/customer-signup",
    component: _0158c2a4,
    name: "customer-signup___it"
  }, {
    path: "/it/fitness",
    component: _73613c86,
    name: "fitness___it"
  }, {
    path: "/it/forgot",
    component: _7c4078f6,
    name: "forgot___it"
  }, {
    path: "/it/forgot-password",
    component: _9d9f607c,
    name: "forgot-password___it"
  }, {
    path: "/it/groups",
    component: _7388c90a,
    name: "groups___it"
  }, {
    path: "/it/HBO-signup",
    component: _78f1f5ba,
    name: "HBO-signup___it"
  }, {
    path: "/it/keto",
    component: _1a8d329a,
    name: "keto___it"
  }, {
    path: "/it/login",
    component: _09689566,
    name: "login___it"
  }, {
    path: "/it/regional",
    component: _5453fd10,
    name: "regional___it"
  }, {
    path: "/it/reset",
    component: _2ef93f68,
    name: "reset___it"
  }, {
    path: "/it/schedule",
    component: _4ae20baa,
    name: "schedule___it"
  }, {
    path: "/it/search",
    component: _641c30fb,
    name: "search___it"
  }, {
    path: "/it/settings",
    component: _c4a746e8,
    name: "settings___it"
  }, {
    path: "/it/signup",
    component: _7004046b,
    name: "signup___it"
  }, {
    path: "/it/update-required",
    component: _5b1bb240,
    name: "update-required___it"
  }, {
    path: "/it/users",
    component: _bcb34f46,
    name: "users___it"
  }, {
    path: "/keto/groups",
    component: _be573e74,
    name: "keto-groups___en"
  }, {
    path: "/nl-be/academy",
    component: _622cde39,
    name: "academy___nl-be"
  }, {
    path: "/nl-be/academy-signup",
    component: _537c1aec,
    name: "academy-signup___nl-be"
  }, {
    path: "/nl-be/accept",
    component: _4552d05b,
    name: "accept___nl-be"
  }, {
    path: "/nl-be/accept-contract",
    component: _45328618,
    name: "accept-contract___nl-be"
  }, {
    path: "/nl-be/activate-secondary-mail",
    component: _c73f5e72,
    name: "activate-secondary-mail___nl-be"
  }, {
    path: "/nl-be/admin",
    component: _7130464c,
    name: "admin___nl-be"
  }, {
    path: "/nl-be/biva",
    component: _2ac6c2c6,
    name: "biva___nl-be"
  }, {
    path: "/nl-be/bluetooth",
    component: _35a62a77,
    name: "bluetooth___nl-be"
  }, {
    path: "/nl-be/completeProfile",
    component: _55de4326,
    name: "completeProfile___nl-be"
  }, {
    path: "/nl-be/config",
    component: _7ff61cd6,
    name: "config___nl-be"
  }, {
    path: "/nl-be/customer-signup",
    component: _0158c2a4,
    name: "customer-signup___nl-be"
  }, {
    path: "/nl-be/fitness",
    component: _73613c86,
    name: "fitness___nl-be"
  }, {
    path: "/nl-be/forgot",
    component: _7c4078f6,
    name: "forgot___nl-be"
  }, {
    path: "/nl-be/forgot-password",
    component: _9d9f607c,
    name: "forgot-password___nl-be"
  }, {
    path: "/nl-be/groups",
    component: _7388c90a,
    name: "groups___nl-be"
  }, {
    path: "/nl-be/HBO-signup",
    component: _78f1f5ba,
    name: "HBO-signup___nl-be"
  }, {
    path: "/nl-be/keto",
    component: _1a8d329a,
    name: "keto___nl-be"
  }, {
    path: "/nl-be/login",
    component: _09689566,
    name: "login___nl-be"
  }, {
    path: "/nl-be/regional",
    component: _5453fd10,
    name: "regional___nl-be"
  }, {
    path: "/nl-be/reset",
    component: _2ef93f68,
    name: "reset___nl-be"
  }, {
    path: "/nl-be/schedule",
    component: _4ae20baa,
    name: "schedule___nl-be"
  }, {
    path: "/nl-be/search",
    component: _641c30fb,
    name: "search___nl-be"
  }, {
    path: "/nl-be/settings",
    component: _c4a746e8,
    name: "settings___nl-be"
  }, {
    path: "/nl-be/signup",
    component: _7004046b,
    name: "signup___nl-be"
  }, {
    path: "/nl-be/update-required",
    component: _5b1bb240,
    name: "update-required___nl-be"
  }, {
    path: "/nl-be/users",
    component: _bcb34f46,
    name: "users___nl-be"
  }, {
    path: "/nl/academy",
    component: _622cde39,
    name: "academy___nl"
  }, {
    path: "/nl/academy-signup",
    component: _537c1aec,
    name: "academy-signup___nl"
  }, {
    path: "/nl/accept",
    component: _4552d05b,
    name: "accept___nl"
  }, {
    path: "/nl/accept-contract",
    component: _45328618,
    name: "accept-contract___nl"
  }, {
    path: "/nl/activate-secondary-mail",
    component: _c73f5e72,
    name: "activate-secondary-mail___nl"
  }, {
    path: "/nl/admin",
    component: _7130464c,
    name: "admin___nl"
  }, {
    path: "/nl/biva",
    component: _2ac6c2c6,
    name: "biva___nl"
  }, {
    path: "/nl/bluetooth",
    component: _35a62a77,
    name: "bluetooth___nl"
  }, {
    path: "/nl/completeProfile",
    component: _55de4326,
    name: "completeProfile___nl"
  }, {
    path: "/nl/config",
    component: _7ff61cd6,
    name: "config___nl"
  }, {
    path: "/nl/customer-signup",
    component: _0158c2a4,
    name: "customer-signup___nl"
  }, {
    path: "/nl/fitness",
    component: _73613c86,
    name: "fitness___nl"
  }, {
    path: "/nl/forgot",
    component: _7c4078f6,
    name: "forgot___nl"
  }, {
    path: "/nl/forgot-password",
    component: _9d9f607c,
    name: "forgot-password___nl"
  }, {
    path: "/nl/groups",
    component: _7388c90a,
    name: "groups___nl"
  }, {
    path: "/nl/HBO-signup",
    component: _78f1f5ba,
    name: "HBO-signup___nl"
  }, {
    path: "/nl/keto",
    component: _1a8d329a,
    name: "keto___nl"
  }, {
    path: "/nl/login",
    component: _09689566,
    name: "login___nl"
  }, {
    path: "/nl/regional",
    component: _5453fd10,
    name: "regional___nl"
  }, {
    path: "/nl/reset",
    component: _2ef93f68,
    name: "reset___nl"
  }, {
    path: "/nl/schedule",
    component: _4ae20baa,
    name: "schedule___nl"
  }, {
    path: "/nl/search",
    component: _641c30fb,
    name: "search___nl"
  }, {
    path: "/nl/settings",
    component: _c4a746e8,
    name: "settings___nl"
  }, {
    path: "/nl/signup",
    component: _7004046b,
    name: "signup___nl"
  }, {
    path: "/nl/update-required",
    component: _5b1bb240,
    name: "update-required___nl"
  }, {
    path: "/nl/users",
    component: _bcb34f46,
    name: "users___nl"
  }, {
    path: "/pl/academy",
    component: _622cde39,
    name: "academy___pl"
  }, {
    path: "/pl/academy-signup",
    component: _537c1aec,
    name: "academy-signup___pl"
  }, {
    path: "/pl/accept",
    component: _4552d05b,
    name: "accept___pl"
  }, {
    path: "/pl/accept-contract",
    component: _45328618,
    name: "accept-contract___pl"
  }, {
    path: "/pl/activate-secondary-mail",
    component: _c73f5e72,
    name: "activate-secondary-mail___pl"
  }, {
    path: "/pl/admin",
    component: _7130464c,
    name: "admin___pl"
  }, {
    path: "/pl/biva",
    component: _2ac6c2c6,
    name: "biva___pl"
  }, {
    path: "/pl/bluetooth",
    component: _35a62a77,
    name: "bluetooth___pl"
  }, {
    path: "/pl/completeProfile",
    component: _55de4326,
    name: "completeProfile___pl"
  }, {
    path: "/pl/config",
    component: _7ff61cd6,
    name: "config___pl"
  }, {
    path: "/pl/customer-signup",
    component: _0158c2a4,
    name: "customer-signup___pl"
  }, {
    path: "/pl/fitness",
    component: _73613c86,
    name: "fitness___pl"
  }, {
    path: "/pl/forgot",
    component: _7c4078f6,
    name: "forgot___pl"
  }, {
    path: "/pl/forgot-password",
    component: _9d9f607c,
    name: "forgot-password___pl"
  }, {
    path: "/pl/groups",
    component: _7388c90a,
    name: "groups___pl"
  }, {
    path: "/pl/HBO-signup",
    component: _78f1f5ba,
    name: "HBO-signup___pl"
  }, {
    path: "/pl/keto",
    component: _1a8d329a,
    name: "keto___pl"
  }, {
    path: "/pl/login",
    component: _09689566,
    name: "login___pl"
  }, {
    path: "/pl/regional",
    component: _5453fd10,
    name: "regional___pl"
  }, {
    path: "/pl/reset",
    component: _2ef93f68,
    name: "reset___pl"
  }, {
    path: "/pl/schedule",
    component: _4ae20baa,
    name: "schedule___pl"
  }, {
    path: "/pl/search",
    component: _641c30fb,
    name: "search___pl"
  }, {
    path: "/pl/settings",
    component: _c4a746e8,
    name: "settings___pl"
  }, {
    path: "/pl/signup",
    component: _7004046b,
    name: "signup___pl"
  }, {
    path: "/pl/update-required",
    component: _5b1bb240,
    name: "update-required___pl"
  }, {
    path: "/pl/users",
    component: _bcb34f46,
    name: "users___pl"
  }, {
    path: "/regional/groups",
    component: _0bbc553c,
    name: "regional-groups___en"
  }, {
    path: "/settings/documents",
    component: _590d4272,
    name: "settings-documents___en"
  }, {
    path: "/settings/licenses",
    component: _25738ef0,
    name: "settings-licenses___en"
  }, {
    path: "/zh/academy",
    component: _622cde39,
    name: "academy___zh"
  }, {
    path: "/zh/academy-signup",
    component: _537c1aec,
    name: "academy-signup___zh"
  }, {
    path: "/zh/accept",
    component: _4552d05b,
    name: "accept___zh"
  }, {
    path: "/zh/accept-contract",
    component: _45328618,
    name: "accept-contract___zh"
  }, {
    path: "/zh/activate-secondary-mail",
    component: _c73f5e72,
    name: "activate-secondary-mail___zh"
  }, {
    path: "/zh/admin",
    component: _7130464c,
    name: "admin___zh"
  }, {
    path: "/zh/biva",
    component: _2ac6c2c6,
    name: "biva___zh"
  }, {
    path: "/zh/bluetooth",
    component: _35a62a77,
    name: "bluetooth___zh"
  }, {
    path: "/zh/completeProfile",
    component: _55de4326,
    name: "completeProfile___zh"
  }, {
    path: "/zh/config",
    component: _7ff61cd6,
    name: "config___zh"
  }, {
    path: "/zh/customer-signup",
    component: _0158c2a4,
    name: "customer-signup___zh"
  }, {
    path: "/zh/fitness",
    component: _73613c86,
    name: "fitness___zh"
  }, {
    path: "/zh/forgot",
    component: _7c4078f6,
    name: "forgot___zh"
  }, {
    path: "/zh/forgot-password",
    component: _9d9f607c,
    name: "forgot-password___zh"
  }, {
    path: "/zh/groups",
    component: _7388c90a,
    name: "groups___zh"
  }, {
    path: "/zh/HBO-signup",
    component: _78f1f5ba,
    name: "HBO-signup___zh"
  }, {
    path: "/zh/keto",
    component: _1a8d329a,
    name: "keto___zh"
  }, {
    path: "/zh/login",
    component: _09689566,
    name: "login___zh"
  }, {
    path: "/zh/regional",
    component: _5453fd10,
    name: "regional___zh"
  }, {
    path: "/zh/reset",
    component: _2ef93f68,
    name: "reset___zh"
  }, {
    path: "/zh/schedule",
    component: _4ae20baa,
    name: "schedule___zh"
  }, {
    path: "/zh/search",
    component: _641c30fb,
    name: "search___zh"
  }, {
    path: "/zh/settings",
    component: _c4a746e8,
    name: "settings___zh"
  }, {
    path: "/zh/signup",
    component: _7004046b,
    name: "signup___zh"
  }, {
    path: "/zh/update-required",
    component: _5b1bb240,
    name: "update-required___zh"
  }, {
    path: "/zh/users",
    component: _bcb34f46,
    name: "users___zh"
  }, {
    path: "/de/academy/material",
    component: _53c78f1c,
    name: "academy-material___de"
  }, {
    path: "/de/fitness/groups",
    component: _4052bce9,
    name: "fitness-groups___de"
  }, {
    path: "/de/groups/deleted",
    component: _5c04b1fc,
    name: "groups-deleted___de"
  }, {
    path: "/de/keto/groups",
    component: _be573e74,
    name: "keto-groups___de"
  }, {
    path: "/de/regional/groups",
    component: _0bbc553c,
    name: "regional-groups___de"
  }, {
    path: "/de/settings/documents",
    component: _590d4272,
    name: "settings-documents___de"
  }, {
    path: "/de/settings/licenses",
    component: _25738ef0,
    name: "settings-licenses___de"
  }, {
    path: "/el/academy/material",
    component: _53c78f1c,
    name: "academy-material___el"
  }, {
    path: "/el/fitness/groups",
    component: _4052bce9,
    name: "fitness-groups___el"
  }, {
    path: "/el/groups/deleted",
    component: _5c04b1fc,
    name: "groups-deleted___el"
  }, {
    path: "/el/keto/groups",
    component: _be573e74,
    name: "keto-groups___el"
  }, {
    path: "/el/regional/groups",
    component: _0bbc553c,
    name: "regional-groups___el"
  }, {
    path: "/el/settings/documents",
    component: _590d4272,
    name: "settings-documents___el"
  }, {
    path: "/el/settings/licenses",
    component: _25738ef0,
    name: "settings-licenses___el"
  }, {
    path: "/en-gb/academy/material",
    component: _53c78f1c,
    name: "academy-material___en-gb"
  }, {
    path: "/en-gb/fitness/groups",
    component: _4052bce9,
    name: "fitness-groups___en-gb"
  }, {
    path: "/en-gb/groups/deleted",
    component: _5c04b1fc,
    name: "groups-deleted___en-gb"
  }, {
    path: "/en-gb/keto/groups",
    component: _be573e74,
    name: "keto-groups___en-gb"
  }, {
    path: "/en-gb/regional/groups",
    component: _0bbc553c,
    name: "regional-groups___en-gb"
  }, {
    path: "/en-gb/settings/documents",
    component: _590d4272,
    name: "settings-documents___en-gb"
  }, {
    path: "/en-gb/settings/licenses",
    component: _25738ef0,
    name: "settings-licenses___en-gb"
  }, {
    path: "/es/academy/material",
    component: _53c78f1c,
    name: "academy-material___es"
  }, {
    path: "/es/fitness/groups",
    component: _4052bce9,
    name: "fitness-groups___es"
  }, {
    path: "/es/groups/deleted",
    component: _5c04b1fc,
    name: "groups-deleted___es"
  }, {
    path: "/es/keto/groups",
    component: _be573e74,
    name: "keto-groups___es"
  }, {
    path: "/es/regional/groups",
    component: _0bbc553c,
    name: "regional-groups___es"
  }, {
    path: "/es/settings/documents",
    component: _590d4272,
    name: "settings-documents___es"
  }, {
    path: "/es/settings/licenses",
    component: _25738ef0,
    name: "settings-licenses___es"
  }, {
    path: "/fitness/groups/deleted",
    component: _22d224f0,
    name: "fitness-groups-deleted___en"
  }, {
    path: "/fr/academy/material",
    component: _53c78f1c,
    name: "academy-material___fr"
  }, {
    path: "/fr/fitness/groups",
    component: _4052bce9,
    name: "fitness-groups___fr"
  }, {
    path: "/fr/groups/deleted",
    component: _5c04b1fc,
    name: "groups-deleted___fr"
  }, {
    path: "/fr/keto/groups",
    component: _be573e74,
    name: "keto-groups___fr"
  }, {
    path: "/fr/regional/groups",
    component: _0bbc553c,
    name: "regional-groups___fr"
  }, {
    path: "/fr/settings/documents",
    component: _590d4272,
    name: "settings-documents___fr"
  }, {
    path: "/fr/settings/licenses",
    component: _25738ef0,
    name: "settings-licenses___fr"
  }, {
    path: "/it/academy/material",
    component: _53c78f1c,
    name: "academy-material___it"
  }, {
    path: "/it/fitness/groups",
    component: _4052bce9,
    name: "fitness-groups___it"
  }, {
    path: "/it/groups/deleted",
    component: _5c04b1fc,
    name: "groups-deleted___it"
  }, {
    path: "/it/keto/groups",
    component: _be573e74,
    name: "keto-groups___it"
  }, {
    path: "/it/regional/groups",
    component: _0bbc553c,
    name: "regional-groups___it"
  }, {
    path: "/it/settings/documents",
    component: _590d4272,
    name: "settings-documents___it"
  }, {
    path: "/it/settings/licenses",
    component: _25738ef0,
    name: "settings-licenses___it"
  }, {
    path: "/keto/groups/deleted",
    component: _697a458d,
    name: "keto-groups-deleted___en"
  }, {
    path: "/nl-be/academy/material",
    component: _53c78f1c,
    name: "academy-material___nl-be"
  }, {
    path: "/nl-be/fitness/groups",
    component: _4052bce9,
    name: "fitness-groups___nl-be"
  }, {
    path: "/nl-be/groups/deleted",
    component: _5c04b1fc,
    name: "groups-deleted___nl-be"
  }, {
    path: "/nl-be/keto/groups",
    component: _be573e74,
    name: "keto-groups___nl-be"
  }, {
    path: "/nl-be/regional/groups",
    component: _0bbc553c,
    name: "regional-groups___nl-be"
  }, {
    path: "/nl-be/settings/documents",
    component: _590d4272,
    name: "settings-documents___nl-be"
  }, {
    path: "/nl-be/settings/licenses",
    component: _25738ef0,
    name: "settings-licenses___nl-be"
  }, {
    path: "/nl/academy/material",
    component: _53c78f1c,
    name: "academy-material___nl"
  }, {
    path: "/nl/fitness/groups",
    component: _4052bce9,
    name: "fitness-groups___nl"
  }, {
    path: "/nl/groups/deleted",
    component: _5c04b1fc,
    name: "groups-deleted___nl"
  }, {
    path: "/nl/keto/groups",
    component: _be573e74,
    name: "keto-groups___nl"
  }, {
    path: "/nl/regional/groups",
    component: _0bbc553c,
    name: "regional-groups___nl"
  }, {
    path: "/nl/settings/documents",
    component: _590d4272,
    name: "settings-documents___nl"
  }, {
    path: "/nl/settings/licenses",
    component: _25738ef0,
    name: "settings-licenses___nl"
  }, {
    path: "/pl/academy/material",
    component: _53c78f1c,
    name: "academy-material___pl"
  }, {
    path: "/pl/fitness/groups",
    component: _4052bce9,
    name: "fitness-groups___pl"
  }, {
    path: "/pl/groups/deleted",
    component: _5c04b1fc,
    name: "groups-deleted___pl"
  }, {
    path: "/pl/keto/groups",
    component: _be573e74,
    name: "keto-groups___pl"
  }, {
    path: "/pl/regional/groups",
    component: _0bbc553c,
    name: "regional-groups___pl"
  }, {
    path: "/pl/settings/documents",
    component: _590d4272,
    name: "settings-documents___pl"
  }, {
    path: "/pl/settings/licenses",
    component: _25738ef0,
    name: "settings-licenses___pl"
  }, {
    path: "/regional/groups/deleted",
    component: _8b9216fa,
    name: "regional-groups-deleted___en"
  }, {
    path: "/zh/academy/material",
    component: _53c78f1c,
    name: "academy-material___zh"
  }, {
    path: "/zh/fitness/groups",
    component: _4052bce9,
    name: "fitness-groups___zh"
  }, {
    path: "/zh/groups/deleted",
    component: _5c04b1fc,
    name: "groups-deleted___zh"
  }, {
    path: "/zh/keto/groups",
    component: _be573e74,
    name: "keto-groups___zh"
  }, {
    path: "/zh/regional/groups",
    component: _0bbc553c,
    name: "regional-groups___zh"
  }, {
    path: "/zh/settings/documents",
    component: _590d4272,
    name: "settings-documents___zh"
  }, {
    path: "/zh/settings/licenses",
    component: _25738ef0,
    name: "settings-licenses___zh"
  }, {
    path: "/de/fitness/groups/deleted",
    component: _22d224f0,
    name: "fitness-groups-deleted___de"
  }, {
    path: "/de/keto/groups/deleted",
    component: _697a458d,
    name: "keto-groups-deleted___de"
  }, {
    path: "/de/regional/groups/deleted",
    component: _8b9216fa,
    name: "regional-groups-deleted___de"
  }, {
    path: "/el/fitness/groups/deleted",
    component: _22d224f0,
    name: "fitness-groups-deleted___el"
  }, {
    path: "/el/keto/groups/deleted",
    component: _697a458d,
    name: "keto-groups-deleted___el"
  }, {
    path: "/el/regional/groups/deleted",
    component: _8b9216fa,
    name: "regional-groups-deleted___el"
  }, {
    path: "/en-gb/fitness/groups/deleted",
    component: _22d224f0,
    name: "fitness-groups-deleted___en-gb"
  }, {
    path: "/en-gb/keto/groups/deleted",
    component: _697a458d,
    name: "keto-groups-deleted___en-gb"
  }, {
    path: "/en-gb/regional/groups/deleted",
    component: _8b9216fa,
    name: "regional-groups-deleted___en-gb"
  }, {
    path: "/es/fitness/groups/deleted",
    component: _22d224f0,
    name: "fitness-groups-deleted___es"
  }, {
    path: "/es/keto/groups/deleted",
    component: _697a458d,
    name: "keto-groups-deleted___es"
  }, {
    path: "/es/regional/groups/deleted",
    component: _8b9216fa,
    name: "regional-groups-deleted___es"
  }, {
    path: "/fr/fitness/groups/deleted",
    component: _22d224f0,
    name: "fitness-groups-deleted___fr"
  }, {
    path: "/fr/keto/groups/deleted",
    component: _697a458d,
    name: "keto-groups-deleted___fr"
  }, {
    path: "/fr/regional/groups/deleted",
    component: _8b9216fa,
    name: "regional-groups-deleted___fr"
  }, {
    path: "/it/fitness/groups/deleted",
    component: _22d224f0,
    name: "fitness-groups-deleted___it"
  }, {
    path: "/it/keto/groups/deleted",
    component: _697a458d,
    name: "keto-groups-deleted___it"
  }, {
    path: "/it/regional/groups/deleted",
    component: _8b9216fa,
    name: "regional-groups-deleted___it"
  }, {
    path: "/nl-be/fitness/groups/deleted",
    component: _22d224f0,
    name: "fitness-groups-deleted___nl-be"
  }, {
    path: "/nl-be/keto/groups/deleted",
    component: _697a458d,
    name: "keto-groups-deleted___nl-be"
  }, {
    path: "/nl-be/regional/groups/deleted",
    component: _8b9216fa,
    name: "regional-groups-deleted___nl-be"
  }, {
    path: "/nl/fitness/groups/deleted",
    component: _22d224f0,
    name: "fitness-groups-deleted___nl"
  }, {
    path: "/nl/keto/groups/deleted",
    component: _697a458d,
    name: "keto-groups-deleted___nl"
  }, {
    path: "/nl/regional/groups/deleted",
    component: _8b9216fa,
    name: "regional-groups-deleted___nl"
  }, {
    path: "/pl/fitness/groups/deleted",
    component: _22d224f0,
    name: "fitness-groups-deleted___pl"
  }, {
    path: "/pl/keto/groups/deleted",
    component: _697a458d,
    name: "keto-groups-deleted___pl"
  }, {
    path: "/pl/regional/groups/deleted",
    component: _8b9216fa,
    name: "regional-groups-deleted___pl"
  }, {
    path: "/zh/fitness/groups/deleted",
    component: _22d224f0,
    name: "fitness-groups-deleted___zh"
  }, {
    path: "/zh/keto/groups/deleted",
    component: _697a458d,
    name: "keto-groups-deleted___zh"
  }, {
    path: "/zh/regional/groups/deleted",
    component: _8b9216fa,
    name: "regional-groups-deleted___zh"
  }, {
    path: "/de/biva/regional/:sessionId?",
    component: _5df90514,
    name: "biva-regional-sessionId___de"
  }, {
    path: "/de/biva/sessions/:sessionId?",
    component: _29304bf4,
    name: "biva-sessions-sessionId___de"
  }, {
    path: "/de/fitness/groups/:groupId",
    component: _34f54de4,
    name: "fitness-groups-groupId___de"
  }, {
    path: "/de/keto/groups/:groupId",
    component: _fff4e6aa,
    name: "keto-groups-groupId___de"
  }, {
    path: "/de/regional/groups/:groupId",
    component: _51f6e5a1,
    name: "regional-groups-groupId___de"
  }, {
    path: "/el/biva/regional/:sessionId?",
    component: _5df90514,
    name: "biva-regional-sessionId___el"
  }, {
    path: "/el/biva/sessions/:sessionId?",
    component: _29304bf4,
    name: "biva-sessions-sessionId___el"
  }, {
    path: "/el/fitness/groups/:groupId",
    component: _34f54de4,
    name: "fitness-groups-groupId___el"
  }, {
    path: "/el/keto/groups/:groupId",
    component: _fff4e6aa,
    name: "keto-groups-groupId___el"
  }, {
    path: "/el/regional/groups/:groupId",
    component: _51f6e5a1,
    name: "regional-groups-groupId___el"
  }, {
    path: "/en-gb/biva/regional/:sessionId?",
    component: _5df90514,
    name: "biva-regional-sessionId___en-gb"
  }, {
    path: "/en-gb/biva/sessions/:sessionId?",
    component: _29304bf4,
    name: "biva-sessions-sessionId___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId",
    component: _34f54de4,
    name: "fitness-groups-groupId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId",
    component: _fff4e6aa,
    name: "keto-groups-groupId___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId",
    component: _51f6e5a1,
    name: "regional-groups-groupId___en-gb"
  }, {
    path: "/es/biva/regional/:sessionId?",
    component: _5df90514,
    name: "biva-regional-sessionId___es"
  }, {
    path: "/es/biva/sessions/:sessionId?",
    component: _29304bf4,
    name: "biva-sessions-sessionId___es"
  }, {
    path: "/es/fitness/groups/:groupId",
    component: _34f54de4,
    name: "fitness-groups-groupId___es"
  }, {
    path: "/es/keto/groups/:groupId",
    component: _fff4e6aa,
    name: "keto-groups-groupId___es"
  }, {
    path: "/es/regional/groups/:groupId",
    component: _51f6e5a1,
    name: "regional-groups-groupId___es"
  }, {
    path: "/fr/biva/regional/:sessionId?",
    component: _5df90514,
    name: "biva-regional-sessionId___fr"
  }, {
    path: "/fr/biva/sessions/:sessionId?",
    component: _29304bf4,
    name: "biva-sessions-sessionId___fr"
  }, {
    path: "/fr/fitness/groups/:groupId",
    component: _34f54de4,
    name: "fitness-groups-groupId___fr"
  }, {
    path: "/fr/keto/groups/:groupId",
    component: _fff4e6aa,
    name: "keto-groups-groupId___fr"
  }, {
    path: "/fr/regional/groups/:groupId",
    component: _51f6e5a1,
    name: "regional-groups-groupId___fr"
  }, {
    path: "/it/biva/regional/:sessionId?",
    component: _5df90514,
    name: "biva-regional-sessionId___it"
  }, {
    path: "/it/biva/sessions/:sessionId?",
    component: _29304bf4,
    name: "biva-sessions-sessionId___it"
  }, {
    path: "/it/fitness/groups/:groupId",
    component: _34f54de4,
    name: "fitness-groups-groupId___it"
  }, {
    path: "/it/keto/groups/:groupId",
    component: _fff4e6aa,
    name: "keto-groups-groupId___it"
  }, {
    path: "/it/regional/groups/:groupId",
    component: _51f6e5a1,
    name: "regional-groups-groupId___it"
  }, {
    path: "/nl-be/biva/regional/:sessionId?",
    component: _5df90514,
    name: "biva-regional-sessionId___nl-be"
  }, {
    path: "/nl-be/biva/sessions/:sessionId?",
    component: _29304bf4,
    name: "biva-sessions-sessionId___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId",
    component: _34f54de4,
    name: "fitness-groups-groupId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId",
    component: _fff4e6aa,
    name: "keto-groups-groupId___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId",
    component: _51f6e5a1,
    name: "regional-groups-groupId___nl-be"
  }, {
    path: "/nl/biva/regional/:sessionId?",
    component: _5df90514,
    name: "biva-regional-sessionId___nl"
  }, {
    path: "/nl/biva/sessions/:sessionId?",
    component: _29304bf4,
    name: "biva-sessions-sessionId___nl"
  }, {
    path: "/nl/fitness/groups/:groupId",
    component: _34f54de4,
    name: "fitness-groups-groupId___nl"
  }, {
    path: "/nl/keto/groups/:groupId",
    component: _fff4e6aa,
    name: "keto-groups-groupId___nl"
  }, {
    path: "/nl/regional/groups/:groupId",
    component: _51f6e5a1,
    name: "regional-groups-groupId___nl"
  }, {
    path: "/pl/biva/regional/:sessionId?",
    component: _5df90514,
    name: "biva-regional-sessionId___pl"
  }, {
    path: "/pl/biva/sessions/:sessionId?",
    component: _29304bf4,
    name: "biva-sessions-sessionId___pl"
  }, {
    path: "/pl/fitness/groups/:groupId",
    component: _34f54de4,
    name: "fitness-groups-groupId___pl"
  }, {
    path: "/pl/keto/groups/:groupId",
    component: _fff4e6aa,
    name: "keto-groups-groupId___pl"
  }, {
    path: "/pl/regional/groups/:groupId",
    component: _51f6e5a1,
    name: "regional-groups-groupId___pl"
  }, {
    path: "/zh/biva/regional/:sessionId?",
    component: _5df90514,
    name: "biva-regional-sessionId___zh"
  }, {
    path: "/zh/biva/sessions/:sessionId?",
    component: _29304bf4,
    name: "biva-sessions-sessionId___zh"
  }, {
    path: "/zh/fitness/groups/:groupId",
    component: _34f54de4,
    name: "fitness-groups-groupId___zh"
  }, {
    path: "/zh/keto/groups/:groupId",
    component: _fff4e6aa,
    name: "keto-groups-groupId___zh"
  }, {
    path: "/zh/regional/groups/:groupId",
    component: _51f6e5a1,
    name: "regional-groups-groupId___zh"
  }, {
    path: "/de/fitness/groups/:groupId/deleted",
    component: _45ce4bd5,
    name: "fitness-groups-groupId-deleted___de"
  }, {
    path: "/de/keto/groups/:groupId/deleted",
    component: _41100c32,
    name: "keto-groups-groupId-deleted___de"
  }, {
    path: "/de/regional/groups/:groupId/deleted",
    component: _5c0effa8,
    name: "regional-groups-groupId-deleted___de"
  }, {
    path: "/el/fitness/groups/:groupId/deleted",
    component: _45ce4bd5,
    name: "fitness-groups-groupId-deleted___el"
  }, {
    path: "/el/keto/groups/:groupId/deleted",
    component: _41100c32,
    name: "keto-groups-groupId-deleted___el"
  }, {
    path: "/el/regional/groups/:groupId/deleted",
    component: _5c0effa8,
    name: "regional-groups-groupId-deleted___el"
  }, {
    path: "/en-gb/fitness/groups/:groupId/deleted",
    component: _45ce4bd5,
    name: "fitness-groups-groupId-deleted___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/deleted",
    component: _41100c32,
    name: "keto-groups-groupId-deleted___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/deleted",
    component: _5c0effa8,
    name: "regional-groups-groupId-deleted___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/deleted",
    component: _45ce4bd5,
    name: "fitness-groups-groupId-deleted___es"
  }, {
    path: "/es/keto/groups/:groupId/deleted",
    component: _41100c32,
    name: "keto-groups-groupId-deleted___es"
  }, {
    path: "/es/regional/groups/:groupId/deleted",
    component: _5c0effa8,
    name: "regional-groups-groupId-deleted___es"
  }, {
    path: "/fr/fitness/groups/:groupId/deleted",
    component: _45ce4bd5,
    name: "fitness-groups-groupId-deleted___fr"
  }, {
    path: "/fr/keto/groups/:groupId/deleted",
    component: _41100c32,
    name: "keto-groups-groupId-deleted___fr"
  }, {
    path: "/fr/regional/groups/:groupId/deleted",
    component: _5c0effa8,
    name: "regional-groups-groupId-deleted___fr"
  }, {
    path: "/it/fitness/groups/:groupId/deleted",
    component: _45ce4bd5,
    name: "fitness-groups-groupId-deleted___it"
  }, {
    path: "/it/keto/groups/:groupId/deleted",
    component: _41100c32,
    name: "keto-groups-groupId-deleted___it"
  }, {
    path: "/it/regional/groups/:groupId/deleted",
    component: _5c0effa8,
    name: "regional-groups-groupId-deleted___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/deleted",
    component: _45ce4bd5,
    name: "fitness-groups-groupId-deleted___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/deleted",
    component: _41100c32,
    name: "keto-groups-groupId-deleted___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/deleted",
    component: _5c0effa8,
    name: "regional-groups-groupId-deleted___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/deleted",
    component: _45ce4bd5,
    name: "fitness-groups-groupId-deleted___nl"
  }, {
    path: "/nl/keto/groups/:groupId/deleted",
    component: _41100c32,
    name: "keto-groups-groupId-deleted___nl"
  }, {
    path: "/nl/regional/groups/:groupId/deleted",
    component: _5c0effa8,
    name: "regional-groups-groupId-deleted___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/deleted",
    component: _45ce4bd5,
    name: "fitness-groups-groupId-deleted___pl"
  }, {
    path: "/pl/keto/groups/:groupId/deleted",
    component: _41100c32,
    name: "keto-groups-groupId-deleted___pl"
  }, {
    path: "/pl/regional/groups/:groupId/deleted",
    component: _5c0effa8,
    name: "regional-groups-groupId-deleted___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/deleted",
    component: _45ce4bd5,
    name: "fitness-groups-groupId-deleted___zh"
  }, {
    path: "/zh/keto/groups/:groupId/deleted",
    component: _41100c32,
    name: "keto-groups-groupId-deleted___zh"
  }, {
    path: "/zh/regional/groups/:groupId/deleted",
    component: _5c0effa8,
    name: "regional-groups-groupId-deleted___zh"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?",
    component: _11391d1f,
    name: "fitness-groups-groupId-patients-patientId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?",
    component: _2dac0cfc,
    name: "keto-groups-groupId-patients-patientId___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?",
    component: _4596ad1c,
    name: "regional-groups-groupId-patients-patientId___de"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?",
    component: _11391d1f,
    name: "fitness-groups-groupId-patients-patientId___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?",
    component: _2dac0cfc,
    name: "keto-groups-groupId-patients-patientId___el"
  }, {
    path: "/el/regional/groups/:groupId/patients/:patientId?",
    component: _4596ad1c,
    name: "regional-groups-groupId-patients-patientId___el"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?",
    component: _11391d1f,
    name: "fitness-groups-groupId-patients-patientId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?",
    component: _2dac0cfc,
    name: "keto-groups-groupId-patients-patientId___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?",
    component: _4596ad1c,
    name: "regional-groups-groupId-patients-patientId___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?",
    component: _11391d1f,
    name: "fitness-groups-groupId-patients-patientId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?",
    component: _2dac0cfc,
    name: "keto-groups-groupId-patients-patientId___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?",
    component: _4596ad1c,
    name: "regional-groups-groupId-patients-patientId___es"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?",
    component: _11391d1f,
    name: "fitness-groups-groupId-patients-patientId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?",
    component: _2dac0cfc,
    name: "keto-groups-groupId-patients-patientId___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?",
    component: _4596ad1c,
    name: "regional-groups-groupId-patients-patientId___fr"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?",
    component: _11391d1f,
    name: "fitness-groups-groupId-patients-patientId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?",
    component: _2dac0cfc,
    name: "keto-groups-groupId-patients-patientId___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?",
    component: _4596ad1c,
    name: "regional-groups-groupId-patients-patientId___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?",
    component: _11391d1f,
    name: "fitness-groups-groupId-patients-patientId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?",
    component: _2dac0cfc,
    name: "keto-groups-groupId-patients-patientId___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?",
    component: _4596ad1c,
    name: "regional-groups-groupId-patients-patientId___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?",
    component: _11391d1f,
    name: "fitness-groups-groupId-patients-patientId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?",
    component: _2dac0cfc,
    name: "keto-groups-groupId-patients-patientId___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?",
    component: _4596ad1c,
    name: "regional-groups-groupId-patients-patientId___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?",
    component: _11391d1f,
    name: "fitness-groups-groupId-patients-patientId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?",
    component: _2dac0cfc,
    name: "keto-groups-groupId-patients-patientId___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?",
    component: _4596ad1c,
    name: "regional-groups-groupId-patients-patientId___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?",
    component: _11391d1f,
    name: "fitness-groups-groupId-patients-patientId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?",
    component: _2dac0cfc,
    name: "keto-groups-groupId-patients-patientId___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?",
    component: _4596ad1c,
    name: "regional-groups-groupId-patients-patientId___zh"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _1f2c6fa8,
    name: "fitness-groups-groupId-patients-patientId-somatotype___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _4955e1eb,
    name: "keto-groups-groupId-patients-patientId-somatotype___de"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _1f2c6fa8,
    name: "fitness-groups-groupId-patients-patientId-somatotype___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _4955e1eb,
    name: "keto-groups-groupId-patients-patientId-somatotype___el"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _1f2c6fa8,
    name: "fitness-groups-groupId-patients-patientId-somatotype___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _4955e1eb,
    name: "keto-groups-groupId-patients-patientId-somatotype___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _1f2c6fa8,
    name: "fitness-groups-groupId-patients-patientId-somatotype___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _4955e1eb,
    name: "keto-groups-groupId-patients-patientId-somatotype___es"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _1f2c6fa8,
    name: "fitness-groups-groupId-patients-patientId-somatotype___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _4955e1eb,
    name: "keto-groups-groupId-patients-patientId-somatotype___fr"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _1f2c6fa8,
    name: "fitness-groups-groupId-patients-patientId-somatotype___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _4955e1eb,
    name: "keto-groups-groupId-patients-patientId-somatotype___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _1f2c6fa8,
    name: "fitness-groups-groupId-patients-patientId-somatotype___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _4955e1eb,
    name: "keto-groups-groupId-patients-patientId-somatotype___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _1f2c6fa8,
    name: "fitness-groups-groupId-patients-patientId-somatotype___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _4955e1eb,
    name: "keto-groups-groupId-patients-patientId-somatotype___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _1f2c6fa8,
    name: "fitness-groups-groupId-patients-patientId-somatotype___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _4955e1eb,
    name: "keto-groups-groupId-patients-patientId-somatotype___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _1f2c6fa8,
    name: "fitness-groups-groupId-patients-patientId-somatotype___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _4955e1eb,
    name: "keto-groups-groupId-patients-patientId-somatotype___zh"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _5e125be8,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _d43a16aa,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3a23fcf5,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___de"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _5e125be8,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _d43a16aa,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___el"
  }, {
    path: "/el/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3a23fcf5,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___el"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _5e125be8,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _d43a16aa,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3a23fcf5,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _5e125be8,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _d43a16aa,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3a23fcf5,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___es"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _5e125be8,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _d43a16aa,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3a23fcf5,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___fr"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _5e125be8,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _d43a16aa,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3a23fcf5,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _5e125be8,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _d43a16aa,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3a23fcf5,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _5e125be8,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _d43a16aa,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3a23fcf5,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _5e125be8,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _d43a16aa,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3a23fcf5,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _5e125be8,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _d43a16aa,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3a23fcf5,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___zh"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _79c349b6,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _2bfe05fb,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _a172050a,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _4c2e5cfa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _5e7f5ac9,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _33f95afc,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _dd5cd778,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _08c24b30,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _1424c6fe,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _54cb8584,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _7d87f2d7,
    name: "keto-groups-groupId-patients-patientId-exams-examId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _500c31e8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _5ffc784e,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _2d59fd21,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _62b63966,
    name: "regional-groups-groupId-patients-patientId-exams-examId___de"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _79c349b6,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _2bfe05fb,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _a172050a,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _4c2e5cfa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _5e7f5ac9,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _33f95afc,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _dd5cd778,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _08c24b30,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _1424c6fe,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _54cb8584,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _7d87f2d7,
    name: "keto-groups-groupId-patients-patientId-exams-examId___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _500c31e8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _5ffc784e,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _2d59fd21,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___el"
  }, {
    path: "/el/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _62b63966,
    name: "regional-groups-groupId-patients-patientId-exams-examId___el"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _79c349b6,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _2bfe05fb,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _a172050a,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _4c2e5cfa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _5e7f5ac9,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _33f95afc,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _dd5cd778,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _08c24b30,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _1424c6fe,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _54cb8584,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _7d87f2d7,
    name: "keto-groups-groupId-patients-patientId-exams-examId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _500c31e8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _5ffc784e,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _2d59fd21,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _62b63966,
    name: "regional-groups-groupId-patients-patientId-exams-examId___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _79c349b6,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _2bfe05fb,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _a172050a,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _4c2e5cfa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _5e7f5ac9,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _33f95afc,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _dd5cd778,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _08c24b30,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _1424c6fe,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _54cb8584,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _7d87f2d7,
    name: "keto-groups-groupId-patients-patientId-exams-examId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _500c31e8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _5ffc784e,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _2d59fd21,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _62b63966,
    name: "regional-groups-groupId-patients-patientId-exams-examId___es"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _79c349b6,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _2bfe05fb,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _a172050a,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _4c2e5cfa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _5e7f5ac9,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _33f95afc,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _dd5cd778,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _08c24b30,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _1424c6fe,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _54cb8584,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _7d87f2d7,
    name: "keto-groups-groupId-patients-patientId-exams-examId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _500c31e8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _5ffc784e,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _2d59fd21,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _62b63966,
    name: "regional-groups-groupId-patients-patientId-exams-examId___fr"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _79c349b6,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _2bfe05fb,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _a172050a,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _4c2e5cfa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _5e7f5ac9,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _33f95afc,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _dd5cd778,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _08c24b30,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _1424c6fe,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _54cb8584,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _7d87f2d7,
    name: "keto-groups-groupId-patients-patientId-exams-examId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _500c31e8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _5ffc784e,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _2d59fd21,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _62b63966,
    name: "regional-groups-groupId-patients-patientId-exams-examId___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _79c349b6,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _2bfe05fb,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _a172050a,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _4c2e5cfa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _5e7f5ac9,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _33f95afc,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _dd5cd778,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _08c24b30,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _1424c6fe,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _54cb8584,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _7d87f2d7,
    name: "keto-groups-groupId-patients-patientId-exams-examId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _500c31e8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _5ffc784e,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _2d59fd21,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _62b63966,
    name: "regional-groups-groupId-patients-patientId-exams-examId___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _79c349b6,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _2bfe05fb,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _a172050a,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _4c2e5cfa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _5e7f5ac9,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _33f95afc,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _dd5cd778,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _08c24b30,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _1424c6fe,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _54cb8584,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _7d87f2d7,
    name: "keto-groups-groupId-patients-patientId-exams-examId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _500c31e8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _5ffc784e,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _2d59fd21,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _62b63966,
    name: "regional-groups-groupId-patients-patientId-exams-examId___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _79c349b6,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _2bfe05fb,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _a172050a,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _4c2e5cfa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _5e7f5ac9,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _33f95afc,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _dd5cd778,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _08c24b30,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _1424c6fe,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _54cb8584,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _7d87f2d7,
    name: "keto-groups-groupId-patients-patientId-exams-examId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _500c31e8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _5ffc784e,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _2d59fd21,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _62b63966,
    name: "regional-groups-groupId-patients-patientId-exams-examId___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _79c349b6,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _2bfe05fb,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _a172050a,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _4c2e5cfa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _5e7f5ac9,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _33f95afc,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _dd5cd778,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _08c24b30,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _1424c6fe,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _54cb8584,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _7d87f2d7,
    name: "keto-groups-groupId-patients-patientId-exams-examId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _500c31e8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _5ffc784e,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _2d59fd21,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _62b63966,
    name: "regional-groups-groupId-patients-patientId-exams-examId___zh"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _822963e6,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _deffb8a2,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _73422a9e,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _7afddede,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _90537658,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _15163d20,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _bf974e9e,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _cfd0a4a2,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _c3687dbc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _3a0bdebf,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _51c0fefa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _0c49af08,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _46ea7aea,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _649f0bdc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _7a35c7ee,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _3c4f261a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _2c4d795f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _28f11f3b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _a9e3a060,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _6c5be0ad,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _0ccb4f40,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _50e9e3c8,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _1cca0b30,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _40ed0138,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _44f8c210,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _5c689368,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _7c35e6ce,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _63249fe1,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _56f0b79e,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _a96844e6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _5ce422e4,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _832a251c,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _75db2a3f,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _57b90ffc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _1a9ee750,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _6988e986,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _42404982,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _56054ece,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _0630f955,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _66886397,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _d6d8fb1e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _d0a12de0,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _315600e2,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _0df5d1fe,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _34532620,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _b33f6af4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _191903ba,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _d9c6988e,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _8e5b17e6,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _36dbf232,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7745c035,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _9f7df370,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _45aed450,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _d5cacc78,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___de"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _822963e6,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _deffb8a2,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _73422a9e,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _7afddede,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _90537658,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _15163d20,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _bf974e9e,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _cfd0a4a2,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _c3687dbc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _3a0bdebf,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _51c0fefa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _0c49af08,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _46ea7aea,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _649f0bdc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _7a35c7ee,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _3c4f261a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _2c4d795f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _28f11f3b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _a9e3a060,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _6c5be0ad,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _0ccb4f40,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _50e9e3c8,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _1cca0b30,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _40ed0138,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _44f8c210,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _5c689368,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _7c35e6ce,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _63249fe1,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _56f0b79e,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _a96844e6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _5ce422e4,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _832a251c,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _75db2a3f,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _57b90ffc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _1a9ee750,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _6988e986,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _42404982,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _56054ece,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _0630f955,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _66886397,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _d6d8fb1e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _d0a12de0,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _315600e2,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _0df5d1fe,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _34532620,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _b33f6af4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _191903ba,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _d9c6988e,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _8e5b17e6,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _36dbf232,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___el"
  }, {
    path: "/el/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7745c035,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___el"
  }, {
    path: "/el/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _9f7df370,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___el"
  }, {
    path: "/el/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _45aed450,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___el"
  }, {
    path: "/el/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _d5cacc78,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___el"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _822963e6,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _deffb8a2,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _73422a9e,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _7afddede,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _90537658,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _15163d20,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _bf974e9e,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _cfd0a4a2,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _c3687dbc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _3a0bdebf,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _51c0fefa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _0c49af08,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _46ea7aea,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _649f0bdc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _7a35c7ee,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _3c4f261a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _2c4d795f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _28f11f3b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _a9e3a060,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _6c5be0ad,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _0ccb4f40,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _50e9e3c8,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _1cca0b30,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _40ed0138,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _44f8c210,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _5c689368,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _7c35e6ce,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _63249fe1,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _56f0b79e,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _a96844e6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _5ce422e4,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _832a251c,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _75db2a3f,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _57b90ffc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _1a9ee750,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _6988e986,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _42404982,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _56054ece,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _0630f955,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _66886397,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _d6d8fb1e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _d0a12de0,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _315600e2,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _0df5d1fe,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _34532620,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _b33f6af4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _191903ba,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _d9c6988e,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _8e5b17e6,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _36dbf232,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7745c035,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _9f7df370,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _45aed450,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _d5cacc78,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _822963e6,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _deffb8a2,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _73422a9e,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _7afddede,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _90537658,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _15163d20,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _bf974e9e,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _cfd0a4a2,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _c3687dbc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _3a0bdebf,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _51c0fefa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _0c49af08,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _46ea7aea,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _649f0bdc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _7a35c7ee,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _3c4f261a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _2c4d795f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _28f11f3b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _a9e3a060,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _6c5be0ad,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _0ccb4f40,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _50e9e3c8,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _1cca0b30,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _40ed0138,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _44f8c210,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _5c689368,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _7c35e6ce,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _63249fe1,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _56f0b79e,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _a96844e6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _5ce422e4,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _832a251c,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _75db2a3f,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _57b90ffc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _1a9ee750,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _6988e986,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _42404982,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _56054ece,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _0630f955,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _66886397,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _d6d8fb1e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _d0a12de0,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _315600e2,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _0df5d1fe,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _34532620,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _b33f6af4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _191903ba,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _d9c6988e,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _8e5b17e6,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _36dbf232,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7745c035,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _9f7df370,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _45aed450,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _d5cacc78,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___es"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _822963e6,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _deffb8a2,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _73422a9e,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _7afddede,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _90537658,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _15163d20,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _bf974e9e,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _cfd0a4a2,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _c3687dbc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _3a0bdebf,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _51c0fefa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _0c49af08,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _46ea7aea,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _649f0bdc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _7a35c7ee,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _3c4f261a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _2c4d795f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _28f11f3b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _a9e3a060,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _6c5be0ad,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _0ccb4f40,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _50e9e3c8,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _1cca0b30,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _40ed0138,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _44f8c210,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _5c689368,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _7c35e6ce,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _63249fe1,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _56f0b79e,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _a96844e6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _5ce422e4,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _832a251c,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _75db2a3f,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _57b90ffc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _1a9ee750,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _6988e986,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _42404982,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _56054ece,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _0630f955,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _66886397,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _d6d8fb1e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _d0a12de0,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _315600e2,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _0df5d1fe,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _34532620,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _b33f6af4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _191903ba,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _d9c6988e,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _8e5b17e6,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _36dbf232,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7745c035,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _9f7df370,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _45aed450,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _d5cacc78,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___fr"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _822963e6,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _deffb8a2,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _73422a9e,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _7afddede,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _90537658,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _15163d20,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _bf974e9e,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _cfd0a4a2,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _c3687dbc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _3a0bdebf,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _51c0fefa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _0c49af08,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _46ea7aea,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _649f0bdc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _7a35c7ee,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _3c4f261a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _2c4d795f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _28f11f3b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _a9e3a060,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _6c5be0ad,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _0ccb4f40,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _50e9e3c8,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _1cca0b30,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _40ed0138,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _44f8c210,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _5c689368,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _7c35e6ce,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _63249fe1,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _56f0b79e,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _a96844e6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _5ce422e4,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _832a251c,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _75db2a3f,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _57b90ffc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _1a9ee750,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _6988e986,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _42404982,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _56054ece,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _0630f955,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _66886397,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _d6d8fb1e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _d0a12de0,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _315600e2,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _0df5d1fe,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _34532620,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _b33f6af4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _191903ba,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _d9c6988e,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _8e5b17e6,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _36dbf232,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7745c035,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _9f7df370,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _45aed450,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _d5cacc78,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _822963e6,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _deffb8a2,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _73422a9e,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _7afddede,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _90537658,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _15163d20,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _bf974e9e,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _cfd0a4a2,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _c3687dbc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _3a0bdebf,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _51c0fefa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _0c49af08,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _46ea7aea,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _649f0bdc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _7a35c7ee,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _3c4f261a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _2c4d795f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _28f11f3b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _a9e3a060,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _6c5be0ad,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _0ccb4f40,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _50e9e3c8,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _1cca0b30,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _40ed0138,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _44f8c210,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _5c689368,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _7c35e6ce,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _63249fe1,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _56f0b79e,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _a96844e6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _5ce422e4,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _832a251c,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _75db2a3f,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _57b90ffc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _1a9ee750,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _6988e986,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _42404982,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _56054ece,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _0630f955,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _66886397,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _d6d8fb1e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _d0a12de0,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _315600e2,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _0df5d1fe,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _34532620,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _b33f6af4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _191903ba,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _d9c6988e,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _8e5b17e6,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _36dbf232,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7745c035,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _9f7df370,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _45aed450,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _d5cacc78,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _822963e6,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _deffb8a2,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _73422a9e,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _7afddede,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _90537658,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _15163d20,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _bf974e9e,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _cfd0a4a2,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _c3687dbc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _3a0bdebf,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _51c0fefa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _0c49af08,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _46ea7aea,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _649f0bdc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _7a35c7ee,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _3c4f261a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _2c4d795f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _28f11f3b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _a9e3a060,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _6c5be0ad,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _0ccb4f40,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _50e9e3c8,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _1cca0b30,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _40ed0138,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _44f8c210,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _5c689368,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _7c35e6ce,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _63249fe1,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _56f0b79e,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _a96844e6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _5ce422e4,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _832a251c,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _75db2a3f,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _57b90ffc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _1a9ee750,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _6988e986,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _42404982,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _56054ece,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _0630f955,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _66886397,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _d6d8fb1e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _d0a12de0,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _315600e2,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _0df5d1fe,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _34532620,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _b33f6af4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _191903ba,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _d9c6988e,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _8e5b17e6,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _36dbf232,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7745c035,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _9f7df370,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _45aed450,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _d5cacc78,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _822963e6,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _deffb8a2,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _73422a9e,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _7afddede,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _90537658,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _15163d20,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _bf974e9e,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _cfd0a4a2,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _c3687dbc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _3a0bdebf,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _51c0fefa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _0c49af08,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _46ea7aea,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _649f0bdc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _7a35c7ee,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _3c4f261a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _2c4d795f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _28f11f3b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _a9e3a060,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _6c5be0ad,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _0ccb4f40,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _50e9e3c8,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _1cca0b30,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _40ed0138,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _44f8c210,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _5c689368,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _7c35e6ce,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _63249fe1,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _56f0b79e,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _a96844e6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _5ce422e4,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _832a251c,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _75db2a3f,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _57b90ffc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _1a9ee750,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _6988e986,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _42404982,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _56054ece,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _0630f955,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _66886397,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _d6d8fb1e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _d0a12de0,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _315600e2,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _0df5d1fe,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _34532620,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _b33f6af4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _191903ba,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _d9c6988e,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _8e5b17e6,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _36dbf232,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7745c035,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _9f7df370,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _45aed450,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _d5cacc78,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _822963e6,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _deffb8a2,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _73422a9e,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _7afddede,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _90537658,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _15163d20,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _bf974e9e,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _cfd0a4a2,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _c3687dbc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _3a0bdebf,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _51c0fefa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _0c49af08,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _46ea7aea,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _649f0bdc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _7a35c7ee,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _3c4f261a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _2c4d795f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _28f11f3b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _a9e3a060,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _6c5be0ad,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _0ccb4f40,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _50e9e3c8,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _1cca0b30,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _40ed0138,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _44f8c210,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _5c689368,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _7c35e6ce,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _63249fe1,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _56f0b79e,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _a96844e6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _5ce422e4,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _832a251c,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _75db2a3f,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _57b90ffc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _1a9ee750,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _6988e986,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _42404982,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _56054ece,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _0630f955,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _66886397,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _d6d8fb1e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _d0a12de0,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _315600e2,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _0df5d1fe,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _34532620,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _b33f6af4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _191903ba,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _d9c6988e,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _8e5b17e6,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _36dbf232,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7745c035,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _9f7df370,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _45aed450,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _d5cacc78,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___zh"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2aebc346,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _74d9875b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _096e1e49,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _1876a978,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _1f1c3d6e,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___de"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2aebc346,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _74d9875b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _096e1e49,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _1876a978,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___el"
  }, {
    path: "/el/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _1f1c3d6e,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___el"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2aebc346,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _74d9875b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _096e1e49,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _1876a978,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _1f1c3d6e,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2aebc346,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _74d9875b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _096e1e49,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _1876a978,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _1f1c3d6e,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___es"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2aebc346,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _74d9875b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _096e1e49,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _1876a978,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _1f1c3d6e,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___fr"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2aebc346,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _74d9875b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _096e1e49,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _1876a978,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _1f1c3d6e,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2aebc346,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _74d9875b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _096e1e49,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _1876a978,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _1f1c3d6e,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2aebc346,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _74d9875b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _096e1e49,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _1876a978,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _1f1c3d6e,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2aebc346,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _74d9875b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _096e1e49,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _1876a978,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _1f1c3d6e,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2aebc346,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _74d9875b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _096e1e49,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _1876a978,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _1f1c3d6e,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___zh"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _5b41bde0,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _4f0c7c6b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _4d0ac33d,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _1f139dee,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _25beee90,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___de"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _5b41bde0,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___el"
  }, {
    path: "/el/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _4f0c7c6b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _4d0ac33d,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___el"
  }, {
    path: "/el/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _1f139dee,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___el"
  }, {
    path: "/el/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _25beee90,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___el"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _5b41bde0,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _4f0c7c6b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _4d0ac33d,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _1f139dee,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _25beee90,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _5b41bde0,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _4f0c7c6b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _4d0ac33d,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _1f139dee,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _25beee90,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___es"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _5b41bde0,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _4f0c7c6b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _4d0ac33d,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _1f139dee,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _25beee90,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___fr"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _5b41bde0,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _4f0c7c6b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _4d0ac33d,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _1f139dee,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _25beee90,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _5b41bde0,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _4f0c7c6b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _4d0ac33d,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _1f139dee,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _25beee90,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _5b41bde0,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _4f0c7c6b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _4d0ac33d,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _1f139dee,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _25beee90,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _5b41bde0,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _4f0c7c6b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _4d0ac33d,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _1f139dee,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _25beee90,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _5b41bde0,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _4f0c7c6b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _4d0ac33d,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _1f139dee,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _25beee90,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___zh"
  }, {
    path: "/biva/regional/:sessionId?",
    component: _5df90514,
    name: "biva-regional-sessionId___en"
  }, {
    path: "/biva/sessions/:sessionId?",
    component: _29304bf4,
    name: "biva-sessions-sessionId___en"
  }, {
    path: "/de/groups/:groupId",
    component: _66dea920,
    name: "groups-groupId___de"
  }, {
    path: "/el/groups/:groupId",
    component: _66dea920,
    name: "groups-groupId___el"
  }, {
    path: "/en-gb/groups/:groupId",
    component: _66dea920,
    name: "groups-groupId___en-gb"
  }, {
    path: "/es/groups/:groupId",
    component: _66dea920,
    name: "groups-groupId___es"
  }, {
    path: "/fitness/groups/:groupId",
    component: _34f54de4,
    name: "fitness-groups-groupId___en"
  }, {
    path: "/fr/groups/:groupId",
    component: _66dea920,
    name: "groups-groupId___fr"
  }, {
    path: "/it/groups/:groupId",
    component: _66dea920,
    name: "groups-groupId___it"
  }, {
    path: "/keto/groups/:groupId",
    component: _fff4e6aa,
    name: "keto-groups-groupId___en"
  }, {
    path: "/nl-be/groups/:groupId",
    component: _66dea920,
    name: "groups-groupId___nl-be"
  }, {
    path: "/nl/groups/:groupId",
    component: _66dea920,
    name: "groups-groupId___nl"
  }, {
    path: "/pl/groups/:groupId",
    component: _66dea920,
    name: "groups-groupId___pl"
  }, {
    path: "/regional/groups/:groupId",
    component: _51f6e5a1,
    name: "regional-groups-groupId___en"
  }, {
    path: "/zh/groups/:groupId",
    component: _66dea920,
    name: "groups-groupId___zh"
  }, {
    path: "/de/groups/:groupId/bia",
    component: _54ccdec8,
    name: "groups-groupId-bia___de"
  }, {
    path: "/de/groups/:groupId/deleted",
    component: _53d84132,
    name: "groups-groupId-deleted___de"
  }, {
    path: "/el/groups/:groupId/bia",
    component: _54ccdec8,
    name: "groups-groupId-bia___el"
  }, {
    path: "/el/groups/:groupId/deleted",
    component: _53d84132,
    name: "groups-groupId-deleted___el"
  }, {
    path: "/en-gb/groups/:groupId/bia",
    component: _54ccdec8,
    name: "groups-groupId-bia___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/deleted",
    component: _53d84132,
    name: "groups-groupId-deleted___en-gb"
  }, {
    path: "/es/groups/:groupId/bia",
    component: _54ccdec8,
    name: "groups-groupId-bia___es"
  }, {
    path: "/es/groups/:groupId/deleted",
    component: _53d84132,
    name: "groups-groupId-deleted___es"
  }, {
    path: "/fitness/groups/:groupId/deleted",
    component: _45ce4bd5,
    name: "fitness-groups-groupId-deleted___en"
  }, {
    path: "/fr/groups/:groupId/bia",
    component: _54ccdec8,
    name: "groups-groupId-bia___fr"
  }, {
    path: "/fr/groups/:groupId/deleted",
    component: _53d84132,
    name: "groups-groupId-deleted___fr"
  }, {
    path: "/it/groups/:groupId/bia",
    component: _54ccdec8,
    name: "groups-groupId-bia___it"
  }, {
    path: "/it/groups/:groupId/deleted",
    component: _53d84132,
    name: "groups-groupId-deleted___it"
  }, {
    path: "/keto/groups/:groupId/deleted",
    component: _41100c32,
    name: "keto-groups-groupId-deleted___en"
  }, {
    path: "/nl-be/groups/:groupId/bia",
    component: _54ccdec8,
    name: "groups-groupId-bia___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/deleted",
    component: _53d84132,
    name: "groups-groupId-deleted___nl-be"
  }, {
    path: "/nl/groups/:groupId/bia",
    component: _54ccdec8,
    name: "groups-groupId-bia___nl"
  }, {
    path: "/nl/groups/:groupId/deleted",
    component: _53d84132,
    name: "groups-groupId-deleted___nl"
  }, {
    path: "/pl/groups/:groupId/bia",
    component: _54ccdec8,
    name: "groups-groupId-bia___pl"
  }, {
    path: "/pl/groups/:groupId/deleted",
    component: _53d84132,
    name: "groups-groupId-deleted___pl"
  }, {
    path: "/regional/groups/:groupId/deleted",
    component: _5c0effa8,
    name: "regional-groups-groupId-deleted___en"
  }, {
    path: "/zh/groups/:groupId/bia",
    component: _54ccdec8,
    name: "groups-groupId-bia___zh"
  }, {
    path: "/zh/groups/:groupId/deleted",
    component: _53d84132,
    name: "groups-groupId-deleted___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?",
    component: _5d99989e,
    name: "groups-groupId-patients-patientId___de"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?",
    component: _5d99989e,
    name: "groups-groupId-patients-patientId___el"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?",
    component: _5d99989e,
    name: "groups-groupId-patients-patientId___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?",
    component: _5d99989e,
    name: "groups-groupId-patients-patientId___es"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?",
    component: _11391d1f,
    name: "fitness-groups-groupId-patients-patientId___en"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?",
    component: _5d99989e,
    name: "groups-groupId-patients-patientId___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?",
    component: _5d99989e,
    name: "groups-groupId-patients-patientId___it"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?",
    component: _2dac0cfc,
    name: "keto-groups-groupId-patients-patientId___en"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?",
    component: _5d99989e,
    name: "groups-groupId-patients-patientId___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?",
    component: _5d99989e,
    name: "groups-groupId-patients-patientId___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?",
    component: _5d99989e,
    name: "groups-groupId-patients-patientId___pl"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?",
    component: _4596ad1c,
    name: "regional-groups-groupId-patients-patientId___en"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?",
    component: _5d99989e,
    name: "groups-groupId-patients-patientId___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/activities",
    component: _53b538b8,
    name: "groups-groupId-patients-patientId-activities___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/bodyscan",
    component: _0cabb2f2,
    name: "groups-groupId-patients-patientId-bodyscan___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/grid",
    component: _3d395692,
    name: "groups-groupId-patients-patientId-grid___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/schedule",
    component: _09e8b708,
    name: "groups-groupId-patients-patientId-schedule___de"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/activities",
    component: _53b538b8,
    name: "groups-groupId-patients-patientId-activities___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/bodyscan",
    component: _0cabb2f2,
    name: "groups-groupId-patients-patientId-bodyscan___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/grid",
    component: _3d395692,
    name: "groups-groupId-patients-patientId-grid___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/schedule",
    component: _09e8b708,
    name: "groups-groupId-patients-patientId-schedule___el"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/activities",
    component: _53b538b8,
    name: "groups-groupId-patients-patientId-activities___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/bodyscan",
    component: _0cabb2f2,
    name: "groups-groupId-patients-patientId-bodyscan___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/grid",
    component: _3d395692,
    name: "groups-groupId-patients-patientId-grid___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/schedule",
    component: _09e8b708,
    name: "groups-groupId-patients-patientId-schedule___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/activities",
    component: _53b538b8,
    name: "groups-groupId-patients-patientId-activities___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/bodyscan",
    component: _0cabb2f2,
    name: "groups-groupId-patients-patientId-bodyscan___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/grid",
    component: _3d395692,
    name: "groups-groupId-patients-patientId-grid___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/schedule",
    component: _09e8b708,
    name: "groups-groupId-patients-patientId-schedule___es"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _1f2c6fa8,
    name: "fitness-groups-groupId-patients-patientId-somatotype___en"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/activities",
    component: _53b538b8,
    name: "groups-groupId-patients-patientId-activities___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/bodyscan",
    component: _0cabb2f2,
    name: "groups-groupId-patients-patientId-bodyscan___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/grid",
    component: _3d395692,
    name: "groups-groupId-patients-patientId-grid___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/schedule",
    component: _09e8b708,
    name: "groups-groupId-patients-patientId-schedule___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/activities",
    component: _53b538b8,
    name: "groups-groupId-patients-patientId-activities___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/bodyscan",
    component: _0cabb2f2,
    name: "groups-groupId-patients-patientId-bodyscan___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/grid",
    component: _3d395692,
    name: "groups-groupId-patients-patientId-grid___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/schedule",
    component: _09e8b708,
    name: "groups-groupId-patients-patientId-schedule___it"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _4955e1eb,
    name: "keto-groups-groupId-patients-patientId-somatotype___en"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/activities",
    component: _53b538b8,
    name: "groups-groupId-patients-patientId-activities___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/bodyscan",
    component: _0cabb2f2,
    name: "groups-groupId-patients-patientId-bodyscan___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/grid",
    component: _3d395692,
    name: "groups-groupId-patients-patientId-grid___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/schedule",
    component: _09e8b708,
    name: "groups-groupId-patients-patientId-schedule___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/activities",
    component: _53b538b8,
    name: "groups-groupId-patients-patientId-activities___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/bodyscan",
    component: _0cabb2f2,
    name: "groups-groupId-patients-patientId-bodyscan___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/grid",
    component: _3d395692,
    name: "groups-groupId-patients-patientId-grid___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/schedule",
    component: _09e8b708,
    name: "groups-groupId-patients-patientId-schedule___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/activities",
    component: _53b538b8,
    name: "groups-groupId-patients-patientId-activities___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/bodyscan",
    component: _0cabb2f2,
    name: "groups-groupId-patients-patientId-bodyscan___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/grid",
    component: _3d395692,
    name: "groups-groupId-patients-patientId-grid___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/schedule",
    component: _09e8b708,
    name: "groups-groupId-patients-patientId-schedule___pl"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/activities",
    component: _53b538b8,
    name: "groups-groupId-patients-patientId-activities___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/bodyscan",
    component: _0cabb2f2,
    name: "groups-groupId-patients-patientId-bodyscan___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/grid",
    component: _3d395692,
    name: "groups-groupId-patients-patientId-grid___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/schedule",
    component: _09e8b708,
    name: "groups-groupId-patients-patientId-schedule___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _5f2d6956,
    name: "groups-groupId-patients-patientId-bodyscan-grid___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _ce18ecd4,
    name: "groups-groupId-patients-patientId-exams-deleted___de"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _5f2d6956,
    name: "groups-groupId-patients-patientId-bodyscan-grid___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _ce18ecd4,
    name: "groups-groupId-patients-patientId-exams-deleted___el"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _5f2d6956,
    name: "groups-groupId-patients-patientId-bodyscan-grid___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _ce18ecd4,
    name: "groups-groupId-patients-patientId-exams-deleted___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _5f2d6956,
    name: "groups-groupId-patients-patientId-bodyscan-grid___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _ce18ecd4,
    name: "groups-groupId-patients-patientId-exams-deleted___es"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _5e125be8,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___en"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _5f2d6956,
    name: "groups-groupId-patients-patientId-bodyscan-grid___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _ce18ecd4,
    name: "groups-groupId-patients-patientId-exams-deleted___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _5f2d6956,
    name: "groups-groupId-patients-patientId-bodyscan-grid___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _ce18ecd4,
    name: "groups-groupId-patients-patientId-exams-deleted___it"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _d43a16aa,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___en"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _5f2d6956,
    name: "groups-groupId-patients-patientId-bodyscan-grid___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _ce18ecd4,
    name: "groups-groupId-patients-patientId-exams-deleted___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _5f2d6956,
    name: "groups-groupId-patients-patientId-bodyscan-grid___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _ce18ecd4,
    name: "groups-groupId-patients-patientId-exams-deleted___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _5f2d6956,
    name: "groups-groupId-patients-patientId-bodyscan-grid___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _ce18ecd4,
    name: "groups-groupId-patients-patientId-exams-deleted___pl"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3a23fcf5,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___en"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _5f2d6956,
    name: "groups-groupId-patients-patientId-bodyscan-grid___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _ce18ecd4,
    name: "groups-groupId-patients-patientId-exams-deleted___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _6a61b8aa,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _721d0f8c,
    name: "groups-groupId-patients-patientId-exams-examId___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _50f12af6,
    name: "groups-groupId-patients-patientId-overview-formulaId___de"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _6a61b8aa,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _721d0f8c,
    name: "groups-groupId-patients-patientId-exams-examId___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _50f12af6,
    name: "groups-groupId-patients-patientId-overview-formulaId___el"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _6a61b8aa,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _721d0f8c,
    name: "groups-groupId-patients-patientId-exams-examId___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _50f12af6,
    name: "groups-groupId-patients-patientId-overview-formulaId___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _6a61b8aa,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _721d0f8c,
    name: "groups-groupId-patients-patientId-exams-examId___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _50f12af6,
    name: "groups-groupId-patients-patientId-overview-formulaId___es"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _79c349b6,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _2bfe05fb,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _a172050a,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _4c2e5cfa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _5e7f5ac9,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _33f95afc,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _dd5cd778,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___en"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _6a61b8aa,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _721d0f8c,
    name: "groups-groupId-patients-patientId-exams-examId___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _50f12af6,
    name: "groups-groupId-patients-patientId-overview-formulaId___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _6a61b8aa,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _721d0f8c,
    name: "groups-groupId-patients-patientId-exams-examId___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _50f12af6,
    name: "groups-groupId-patients-patientId-overview-formulaId___it"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _08c24b30,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _1424c6fe,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _54cb8584,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _7d87f2d7,
    name: "keto-groups-groupId-patients-patientId-exams-examId___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _500c31e8,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _5ffc784e,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _2d59fd21,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___en"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _6a61b8aa,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _721d0f8c,
    name: "groups-groupId-patients-patientId-exams-examId___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _50f12af6,
    name: "groups-groupId-patients-patientId-overview-formulaId___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _6a61b8aa,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _721d0f8c,
    name: "groups-groupId-patients-patientId-exams-examId___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _50f12af6,
    name: "groups-groupId-patients-patientId-overview-formulaId___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _6a61b8aa,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _721d0f8c,
    name: "groups-groupId-patients-patientId-exams-examId___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _50f12af6,
    name: "groups-groupId-patients-patientId-overview-formulaId___pl"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _62b63966,
    name: "regional-groups-groupId-patients-patientId-exams-examId___en"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _6a61b8aa,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _721d0f8c,
    name: "groups-groupId-patients-patientId-exams-examId___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _50f12af6,
    name: "groups-groupId-patients-patientId-overview-formulaId___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _61d1e98b,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _09ec1f98,
    name: "groups-groupId-patients-patientId-exams-examId-bia___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _513ca185,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _6970719c,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _38e67661,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _5dc0ae8b,
    name: "groups-groupId-patients-patientId-exams-examId-measures___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _91d50be0,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _314d30e9,
    name: "groups-groupId-patients-patientId-exams-examId-reports___de"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _61d1e98b,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _09ec1f98,
    name: "groups-groupId-patients-patientId-exams-examId-bia___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _513ca185,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _6970719c,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _38e67661,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _5dc0ae8b,
    name: "groups-groupId-patients-patientId-exams-examId-measures___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _91d50be0,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _314d30e9,
    name: "groups-groupId-patients-patientId-exams-examId-reports___el"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _61d1e98b,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _09ec1f98,
    name: "groups-groupId-patients-patientId-exams-examId-bia___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _513ca185,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _6970719c,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _38e67661,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _5dc0ae8b,
    name: "groups-groupId-patients-patientId-exams-examId-measures___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _91d50be0,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _314d30e9,
    name: "groups-groupId-patients-patientId-exams-examId-reports___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _61d1e98b,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _09ec1f98,
    name: "groups-groupId-patients-patientId-exams-examId-bia___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _513ca185,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _6970719c,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _38e67661,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _5dc0ae8b,
    name: "groups-groupId-patients-patientId-exams-examId-measures___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _91d50be0,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _314d30e9,
    name: "groups-groupId-patients-patientId-exams-examId-reports___es"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _822963e6,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _deffb8a2,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _73422a9e,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _7afddede,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _90537658,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _15163d20,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _bf974e9e,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _cfd0a4a2,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _c3687dbc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _3a0bdebf,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _51c0fefa,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _0c49af08,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _46ea7aea,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _649f0bdc,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _7a35c7ee,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _3c4f261a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _2c4d795f,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _28f11f3b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _a9e3a060,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _6c5be0ad,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _0ccb4f40,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _50e9e3c8,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _1cca0b30,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _40ed0138,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___en"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _61d1e98b,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _09ec1f98,
    name: "groups-groupId-patients-patientId-exams-examId-bia___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _513ca185,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _6970719c,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _38e67661,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _5dc0ae8b,
    name: "groups-groupId-patients-patientId-exams-examId-measures___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _91d50be0,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _314d30e9,
    name: "groups-groupId-patients-patientId-exams-examId-reports___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _61d1e98b,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _09ec1f98,
    name: "groups-groupId-patients-patientId-exams-examId-bia___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _513ca185,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _6970719c,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _38e67661,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _5dc0ae8b,
    name: "groups-groupId-patients-patientId-exams-examId-measures___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _91d50be0,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _314d30e9,
    name: "groups-groupId-patients-patientId-exams-examId-reports___it"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _44f8c210,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _5c689368,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _7c35e6ce,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _63249fe1,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _56f0b79e,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _a96844e6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _5ce422e4,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _832a251c,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _75db2a3f,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _57b90ffc,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _1a9ee750,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _6988e986,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _42404982,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _56054ece,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _0630f955,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _66886397,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _d6d8fb1e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _d0a12de0,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _315600e2,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _0df5d1fe,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _34532620,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _b33f6af4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _191903ba,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _d9c6988e,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _8e5b17e6,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _36dbf232,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___en"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _61d1e98b,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _09ec1f98,
    name: "groups-groupId-patients-patientId-exams-examId-bia___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _513ca185,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _6970719c,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _38e67661,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _5dc0ae8b,
    name: "groups-groupId-patients-patientId-exams-examId-measures___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _91d50be0,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _314d30e9,
    name: "groups-groupId-patients-patientId-exams-examId-reports___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _61d1e98b,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _09ec1f98,
    name: "groups-groupId-patients-patientId-exams-examId-bia___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _513ca185,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _6970719c,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _38e67661,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _5dc0ae8b,
    name: "groups-groupId-patients-patientId-exams-examId-measures___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _91d50be0,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _314d30e9,
    name: "groups-groupId-patients-patientId-exams-examId-reports___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _61d1e98b,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _09ec1f98,
    name: "groups-groupId-patients-patientId-exams-examId-bia___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _513ca185,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _6970719c,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _38e67661,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _5dc0ae8b,
    name: "groups-groupId-patients-patientId-exams-examId-measures___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _91d50be0,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _314d30e9,
    name: "groups-groupId-patients-patientId-exams-examId-reports___pl"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _7745c035,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___en"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _9f7df370,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___en"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _45aed450,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___en"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _d5cacc78,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___en"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _61d1e98b,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _09ec1f98,
    name: "groups-groupId-patients-patientId-exams-examId-bia___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _513ca185,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _6970719c,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _38e67661,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _5dc0ae8b,
    name: "groups-groupId-patients-patientId-exams-examId-measures___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _91d50be0,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _314d30e9,
    name: "groups-groupId-patients-patientId-exams-examId-reports___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _2080b143,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___de"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _2080b143,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___el"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _2080b143,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _2080b143,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___es"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _2080b143,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _2080b143,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___it"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _2080b143,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _2080b143,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _2080b143,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___pl"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _2080b143,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _2db266e8,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___de"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _2db266e8,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___el"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _2db266e8,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _2db266e8,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___es"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _2db266e8,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _2db266e8,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___it"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _2db266e8,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _2db266e8,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _2db266e8,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___pl"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _2db266e8,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _6467dd5a,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _01cd1730,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___de"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _6467dd5a,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _01cd1730,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___el"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _6467dd5a,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _01cd1730,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _6467dd5a,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _01cd1730,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___es"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _6467dd5a,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _01cd1730,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _6467dd5a,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _01cd1730,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___it"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _6467dd5a,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _01cd1730,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _6467dd5a,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _01cd1730,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _6467dd5a,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _01cd1730,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___pl"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _6467dd5a,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _01cd1730,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _3e5fff0b,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _35ddf906,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___de"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _3e5fff0b,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _35ddf906,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___el"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _3e5fff0b,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _35ddf906,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _3e5fff0b,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _35ddf906,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___es"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _2aebc346,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _74d9875b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___en"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _3e5fff0b,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _35ddf906,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _3e5fff0b,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _35ddf906,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___it"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _096e1e49,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _1876a978,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___en"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _3e5fff0b,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _35ddf906,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _3e5fff0b,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _35ddf906,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _3e5fff0b,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _35ddf906,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___pl"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _1f1c3d6e,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___en"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _3e5fff0b,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _35ddf906,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _93560a8a,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _3476f6ee,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___de"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _93560a8a,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___el"
  }, {
    path: "/el/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _3476f6ee,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___el"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _93560a8a,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _3476f6ee,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _93560a8a,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _3476f6ee,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___es"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _5b41bde0,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _4f0c7c6b,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___en"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _93560a8a,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _3476f6ee,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _93560a8a,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _3476f6ee,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___it"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _4d0ac33d,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _1f139dee,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___en"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _93560a8a,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _3476f6ee,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _93560a8a,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _3476f6ee,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _93560a8a,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _3476f6ee,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___pl"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _25beee90,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___en"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _93560a8a,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _3476f6ee,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___zh"
  }, {
    path: "/groups/:groupId",
    component: _66dea920,
    name: "groups-groupId___en"
  }, {
    path: "/groups/:groupId/bia",
    component: _54ccdec8,
    name: "groups-groupId-bia___en"
  }, {
    path: "/groups/:groupId/deleted",
    component: _53d84132,
    name: "groups-groupId-deleted___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?",
    component: _5d99989e,
    name: "groups-groupId-patients-patientId___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/activities",
    component: _53b538b8,
    name: "groups-groupId-patients-patientId-activities___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/bodyscan",
    component: _0cabb2f2,
    name: "groups-groupId-patients-patientId-bodyscan___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/grid",
    component: _3d395692,
    name: "groups-groupId-patients-patientId-grid___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/schedule",
    component: _09e8b708,
    name: "groups-groupId-patients-patientId-schedule___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _5f2d6956,
    name: "groups-groupId-patients-patientId-bodyscan-grid___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _ce18ecd4,
    name: "groups-groupId-patients-patientId-exams-deleted___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _6a61b8aa,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _721d0f8c,
    name: "groups-groupId-patients-patientId-exams-examId___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _50f12af6,
    name: "groups-groupId-patients-patientId-overview-formulaId___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _61d1e98b,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _09ec1f98,
    name: "groups-groupId-patients-patientId-exams-examId-bia___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _513ca185,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _6970719c,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _38e67661,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _5dc0ae8b,
    name: "groups-groupId-patients-patientId-exams-examId-measures___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _91d50be0,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _314d30e9,
    name: "groups-groupId-patients-patientId-exams-examId-reports___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _2080b143,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _2db266e8,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _6467dd5a,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _01cd1730,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _3e5fff0b,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _35ddf906,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _93560a8a,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _3476f6ee,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___en"
  }, {
    path: "/",
    component: _3fc0f24f,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
